/* Boostrap */
@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1248px; } }

.container {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  margin-left: -12px;
  margin-right: -12px;
  &> .col,
  &> [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px; }
  &.no-gutter {
    margin-left: 0;
    margin-right: 0;
    &> .col,
    &> [class*="col-"] {
      padding-left: 0;
      padding-right: 0; } }
  &.double-gutter {
    margin-left: -32px;
    margin-right: -32px;
    &> .col,
    &> [class*="col-"] {
      padding-left: 32px;
      padding-right: 32px; } } }


/* Typography */
h1, h2, h3, h4, h5, h6 {
  @include font-title();
  color: #1B1536;
  margin-top: 0;
  letter-spacing: -0.02em;
  margin-bottom: 1rem; }

h1 {
  font-size: 2.4rem; // 2.8rem
  line-height: 1.167em;
  @include font-title();
  &.large {
    font-size: rem(96);
    line-height: rem(96); }
  @include responsive(1399px, 0) {
    font-size: 2.2rem;
    line-height: 1.2em; }
  @include mobile {
    &:not(.large) {
      font-size: rem(56) !important;
      line-height: 1.250em; } } }

h2,
.h2 {
  font-size: 2rem; // 2.5rem
  line-height: 1.2em;
  @include responsive(1399px, 0) {
    font-size: 2.1rem; }
  @include mtablet {
    font-size: 2rem !important;
    line-height: 1.333em !important; }
  @include mobile {
    font-size: 1.714rem !important; } }

h3 {
  font-size: 1.6rem; // 2rem
  line-height: 1.333em;
  @include responsive(1399px, 0) {
    font-size: 1.7rem; }
  @include mtablet {
    font-size: 1.5rem !important;
    line-height: 1.333em; } }

h4 {
  font-size: rem(24);
  line-height: 1.5em; }

.small {
  font-size: 0.875rem;
  line-height: 1.429em; }

a {
  color: $link;
  transition: color .3s ease-in-out;
  &:hover {
    text-decoration: underline; } }

p {
  margin: 0 0 1.5rem;
  &:last-child {
    margin-bottom: 0; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }


article {
  h1, h2, h3, h4, h5, h6 {
    @include font-700();
    color: $primary;
    margin-top: rem(40); }
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    &:last-child {
      margin-bottom: 0; } }

  a {
    &:hover {
      text-decoration: underline; } }

  blockquote {
    // font-style: italic
    q,
    p {
      font-size: rem(28);
      line-height: rem(40);
      color: $secondary;
      @include font-600;
      position: relative;
      &:after {
        display: none; }
      &:before {
        content: "";
        display: block;
        width: rem(32);
        height: rem(32);
        background: url(../images/icon-blockquote.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: rem(-60); } }
    cite {
      position: absolute;
      color: #1B1536;
      padding-left: rem(32);
      font-size: rem(16);
      line-height: 1.5em;
      font-style: normal;
      text-align: right;
      right: 0;
      bottom: rem(-24);
      &:before {
        content: "";
        width: rem(24);
        height: 1px;
        background: $primary;
        position: absolute;
        left: 0;
        bottom: rem(5); } }
    .quote-writer {
      text-align: right;
      position: relative;
      span {
        position: relative;
        color: #1B1536;
        padding-left: rem(32);
        &:before {
          content: "";
          width: rem(24);
          height: 1px;
          background: $primary;
          position: absolute;
          left: 0;
          bottom: rem(8); } } } }

  li {
    ul, ol {
      margin: 0 1.5rem;
      padding-top: 10px; } }

  ul, ol {
    margin: 0 0 2rem;
    padding-left: 1.5rem;
    list-style: none;
    counter-reset: item;
    &:last-child {
      margin: 0; }
    li {
      margin-bottom: .75rem;
      list-style: none;
      position: relative;
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        color: #E05B11;
        @include font-600();
        position: absolute;
        top: 0;
        left: rem(-20); }
      &:last-child {
        margin: 0; } }

    &[style="list-style-type: lower-alpha;"] {
      li {
        &::before {
          content: counter(item, lower-alpha) "."; } } } }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } }
  figure {
    img {
      display: block;
      width: 100%;
      height: auto; } } }


/* Base */
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

hr {
  border: 0;
  border-top: 1px solid $border; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

@include responsive(0,1200px) {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #F9F9F9; }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
      background-color: #F5F5F5; }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 10px;
      border: 1px solid $primary; } } }


html, body {
  font-size: 16px;
  line-height: 24px;
  font-family: "SFProTextRegular", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 500;
  padding: 0;
  margin: 0;
  background-color: #FFFFFF;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @include mtablet {
    font-size: 14px;
    line-height: 1.5em; } }


.web-wrapper {
  width: 100%;
  overflow: hidden; }
