// Color
$primary        : #1B1536;
$secondary      : #E05B11;
$grey           : #5D6E7C;
$white          : #FFFFFF;
$bg-dark        : #2E2E2E;
$border         : #E2E2E2;
$muted          : #B4B4B4;
$text           : rgba(27, 21, 54, 0.75);
$text-light     : #656567;
$hover          : #EBF3FE;
$link           : #5567C3;
$success        : #4BB7AC;
$danger         : #D74022;
$warning        : #F3C44A;
$info           : #74CFE3;
$overlay        : #14161A;
