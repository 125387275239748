/* Header */
.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: all .3s ease-in-out;
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    transition: all .3s ease-in-out;
    position: relative; }
  &-logo {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding: rem(24) rem(40);
    transition: all .3s ease-in-out;
    a {
      display: block;
      img {
        max-height: rem(28); } } }
  &-menu {
    display: flex;
    align-items: stretch;
    height: 100%;
    transition: all .3s ease-in-out;
    // margin-left: rem(-16)
 }    // margin-right: rem(-16)

  &.fixed {
    background: $primary;
    top: rem(-100);
    .header {
      &-logo {
        padding: rem(16) rem(20); } }
    &.header-scnd {
      .mobile-menu {
        span {
          background: #FFF; } } }
    .sub-menu.big {
      top: rem(59); }
    &.show-top {
      top: 0; } }
  &.header-scnd {
    .header-logo {
      img.colored {
        display: none; } }
    .menu-item {
      &.freechart {
        color: #FFF;
        a {
          color: #FFF; } }
      .sub-menu {
        &:not(.big) {
          a {
            &:hover {
              color: #FFF !important; } } }
        &.big {
          a {
            &:hover {
              color: $secondary !important; } } } } }
    &:not(.fixed) {
      border-bottom: 1px solid rgba(27, 21, 54, 0.25);
      .header-logo {
        border-right: 1px solid rgba(27, 21, 54, 0.25);
        img {
          display: none;
          &.colored {
            display: block; } } }
      .menu-item:not(.freechart) {
        a:not(.btn):not(.btn-arrow) {
          color: $primary; }
        &.has-sub {
          &:after {
            background: url(../images/ic-carret-blue.png); } }
        &.freechart {
          color: #FFF;
          a {
            color: #FFF; } } } } }

  .menu-item {
    padding-top: rem(16);
    padding-left: rem(16);
    padding-right: rem(16);
    padding-bottom: rem(16);
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
    a {
      color: #FFF;
      text-decoration: none;
      @include font-600; }
    &.has-sub {
      position: relative;
      padding-right: rem(24);
      &:after {
        content: "";
        width: rem(16);
        height: rem(16);
        background: url(../images/ic-carret-white.png) no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - #{rem(8)});
        right: 0;
        transition: all .3s ease-in-out; }

      @include minltablet() {
        &:hover {
          &:after {
            transform: rotate(180deg); }
          .sub-menu {
            max-height: max-content;
            opacity: 1;
            visibility: visible;
 } } } }            // padding: rem(40)


    &.freechart {
      background: #E05B11;
      padding-left: rem(42);
      padding-right: rem(42);
      margin-left: rem(24); } }

  .sub-menu {
    position: absolute;
    min-width: rem(280);
    max-width: 100%;
    top: 100%;
    // right: calc(100% - 196px)
    left: 0;// -50%
    margin: 0 auto;
    // padding: 0.75rem
    font-size: 1rem;
    line-height: 1.5em;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    z-index: 3;
    background: #FFF;
    box-shadow: 0px 141px 56px rgba(0, 0, 0, 0.01), 0px 79px 48px rgba(0, 0, 0, 0.05), 0px 35px 35px rgba(0, 0, 0, 0.09), 0px 9px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    &:not(.big) {
      left: rem(16);
      a {
        transition: all .3s ease;
        @include font-600;
        display: block;
        padding: rem(12);
        color: #1B1536;
        &:not(:last-child) {
          border-bottom: 1px solid $border; }
        &:hover {
          background: $link;
          color: #FFF; } } }
    &.big {
      position: fixed;
      top: 77px;
      left: 0;
      width: 100%;
      min-height: rem(400);
      max-height: max-content;
      background: #FFF;
      padding: rem(40);
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 41%;
        height: 100%;
        background-color: #F6F7F9;
        background-image: url(../images/mega-menu-aksen.svg);
        background-size: auto rem(340);
        background-repeat: no-repeat;
        background-position: left bottom;
        border-right: 1px solid #E8E8EB; }
      .mega-header {
        max-width: rem(392);
        h2 {
          font-size: rem(48);
          line-height: 1.3em;
          margin-bottom: rem(24); } }
      .mm-wrapper {
        display: flex;
        align-items: top;
        gap: rem(40); }
      .mm {
        &-items {
          width: 50%;
          max-width: 50%;
          h4 {
            font-size: rem(14);
            line-height: 1.5em;
            padding-bottom: rem(16);
            border-bottom: 1px solid rgba(27, 21, 54, 0.1);
            margin-bottom: rem(16);
            color: rgba(27, 21, 54, 0.5); } }
        &-item {
          .lvl {
            font-size: rem(12);
            line-height: 1.3em;
            color: $secondary;
            @include font-600;
            display: block;
            margin-bottom: rem(12); }
          a {
            color: $primary;
            @include font-600;
            padding: 0;
            display: block;
            margin-bottom: rem(16);
            &:hover {
              color: $secondary; } } } }
      .btn-arrow {
        color: rgba(85, 103, 195, 1);
        @include font-600;
        margin-top: rem(24);
        display: block;
        max-width: max-content; } } } }

.mobile-menu {
  width: rem(24);
  margin: 0 auto;
  cursor: pointer;
  height: rem(24);
  z-index: 5;
  transition: .25s ease-in-out;
  transition-delay: .25s;
  position: absolute;
  top: rem(24);
  right: rem(30);
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: rem(24);
    background: #FFF;
    border-radius: 20px;
    opacity: 1;
    left: 0;
    transform :rotate(0deg) {}
    //transition: opacity .5s .5s,top .5s .4s,transform .5s
    transition: all .4s ease;
    &:nth-child(1) {
      top: rem(5); }
    &:nth-child(2) {
      top: rem(12); }
    &:nth-child(3) {
      top: rem(19);
      display: none; } } }

body.menu-open {
  .header {
    background: #FFF;
    &-logo {
      mask-image: url(../images/logo-selfstrology-colored.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        max-size: contain;
        -webkit-mask-size: contain;
        background-repeat: no-repeat;
        background-color: $primary;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s ease-in-out; }
      img {
        position: relative;
        opacity: 0; } } }

  .mobile-menu {
    span {
      background: $primary;
      &:nth-child(1) {
        top: 6px;
        width: 100%;
        transform: rotate(-135deg); }
      // &:nth-child(2)
      //   top: 6px
      //   width: 100%
      //   opacity: 0
      &:nth-child(2) {
        top: 6px;
        width: 100%;
        transform: rotate(135deg); } } }

  @include max-ipadpro() {
    .header.fixed.header-scnd {
      .mobile-menu {
        span {
          background: $primary !important; } } } } }


.mobile-menu {
  @include min-ipadpro() {
    display: none; }
  @include mobile {
    top: rem(20); } }

@include maxtablet {
  .header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    &.header-scnd {
      border-bottom: 1px solid rgba(27, 21, 54, 0.25);
      .mobile-menu {
        span {
          background: $primary; } } } }


  .header-menu {
    position: fixed;
    width: 100%;
    max-height: 0;
    top: rem(77);
    left: 0;
    background: #FFF;
    display: block;
    padding-top: 0;
    transition: all .3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    & > .menu-item {
      &:not(:last-child):not(.freechart) {
        border-bottom: 1px solid rgba(27, 21, 54, 0.1); } }
    .menu-item {
      display: block;
      height: auto;
      &:not(:last-child):not(.freechart) {
 }        // border-bottom: 1px solid rgba(27, 21, 54, 0.1)
      a {
        color: $primary; }
      &.has-sub {
        &:after {
          top: 21px; }
        &:after {
          background: url(../images/ic-carret-blue.png) no-repeat center;
          right: rem(43); }
        &.active {
          .sub-menu {
            &.big {
              padding: 16px 0; } } } }

      &.freechart {
        margin-top: rem(30);
        // margin-left: rem(40)
        width: max-content;
        a {
          color: #FFF; } }

      &.sub-menu-open {
        position: relative;
        &:before {
          content: "";
          width: 100%;
          height: 50px;
          background: #F6F7F9;
          position: absolute;
          top: 0;
          left: 0; }
        a {
          position: relative;
          z-index: 3; }
        &:after {
          transform: rotate(-180deg); }
        .sub-menu {
          max-height: max-content;
          opacity: 1;
          visibility: visible;
          padding: 16px 0;
          top: 13px; } } }

    .sub-menu {
      position: relative;
      left: 0;
      top: 0;
      box-shadow: none;
      &:not(.big) {
        left: 0;
        a:not(:last-child) {
          border-bottom: 0; } }
      &.big {
        position: relative;
        top: 0;
        left: 0;
        min-height: auto;
        max-height: 0;
        opacity: 0;
        padding: 0;
        &:before {
          display: none; }
        .col-lg-5 {
          display: none; } } } }

  body.menu-open {
    .header {
      &-wrap {
        border-bottom: 1px solid rgba(27, 21, 54, 0.25); }
      &.fixed {
        .header-menu {
          top: rem(60);
          max-height: calc(100vh - #{rem(60)}); } } }
    .header-menu {
      height: 100%;
      max-height: calc(100vh - #{rem(68)});
      padding-top: rem(40);
      opacity: 1;
      visibility: visible; } } }


@include mobile {
  .header {
    &-logo {
      padding: rem(16) rem(24); }
    &-menu {
      top: rem(62); }
    .sub-menu.big {
      .mm-wrapper {
        display: block; }
      .mm-items {
        width: 100%;
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: rem(32); } } } } }


