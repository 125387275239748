/* Custom Plugins */

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  & > .col,
  & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; } }


body.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  z-index: 901;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
  transition: all .5s;
  &:not(.show) {
    opacity: 0;
    .modal-dialog {
      transform: translate(0,-50px); } }
  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    opacity: 1;
    transition: all .3s ease-in-out;
    img {
      display: block;
      width: 26px;
      height: auto; }
    &:hover {
      opacity: .6; } }
  .modal-dialog {
    transition: all .3s ease-in-out;
    max-width: 770px;
    pointer-events: none;
    margin: 0 auto; }
  .modal-content {
    padding: 34px 0;
    background: #fff;
    margin: 30px auto;
    position: relative;
    border-radius: 6px;
    pointer-events: auto;
    box-shadow: 0 4px 8px 0 rgba(119,119,119,0.1), 0 12px 20px 0 rgba(119,119,119,0.2); }

  &--full {
    &:not(.show) {
      opacity: 0;
      .modal-dialog {
        transform: translate(0, 100%); } }
    .close {
      top: 32px;
      right: 40px;
      .svg {
        path {
          fill: $primary; } }
      span {
        position: relative;
        top: 1px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
        color: $primary; } }
    ul.list-check {
      h4 {
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 28px; }
      li {
        padding-bottom: 24px;
        &::before {
          top: 3px; } } }
    .modal {
      &__icon {
        width: 40px;
        margin: 0 auto 8px;
        img {
          display: block;
          width: 100%;
          height: auto; } }
      &__img {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          top: 100%;
          left: 0;
          font-size: 12px;
          line-height: 16px; } }
      &__vid {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          top: 100%;
          left: 0;
          font-size: 12px;
          line-height: 16px; }
        .btn-play {
          z-index: 2;
          display: block;
          width: 68px;
          height: 68px;
          border-radius: 34rem;
          background-color: $primary;
          background-image: url(../images/ic-play.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: calc(50% - 34px);
          right: calc(50% - 34px);
          &:hover {
            @include pulse(0,95,191,primary); } } }
      &__title {
        margin-bottom: 12px; }
      &__desc {
        margin-bottom: 40px;
        p b {
          color: $text; } }
      &-footer {
        position: absolute;
        background: #FFF;
        padding: 32px 6px;
        width: 100%;
        bottom: 0;
        left: 0; }
      &-body {
        // max-height: 90vh
        // overflow: auto
        height: 100%;
        overflow: auto;
        padding-left: 32px;
        padding-right: 32px; }
      &-dialog {
        border-radius: 0;
        display: flex;
        align-items: flex-end;
        width: 100vw;
        height: 100vh;
        max-width: 100%; }
      &-content {
        position: relative;
        width: 100%;
        border-radius: 0;
        margin: 0;
        height: 90vh;
        padding: 64px 6px 20px; } }

    @include mobile() {
      .modal__img {
        margin-bottom: 60px; }
      .modal-body {
        padding-left: 0;
        padding-right: 0; }
      .close {
        top: 16px;
        right: 20px; } } } }

// .modal-open .modal-custom
//   overflow-x: hidden
//   overflow-y: auto

.modal-custom {
  .modal-dialog {
    position: relative;
    max-width: 1226px;
    margin: 48px auto;
    // height: calc(100vh - 96px)
    // display: flex
 }    // align-items: center
  .modal-content {
    padding: 0;
    width: 100%;
    // height: calc(100vh - 96px)
    // overflow-x: hidden
 }    // overflow-y: auto
  .close {
    cursor: pointer;
    right: 0;
    top: -32px;
    width: 32px;
    height: 32px;
    z-index: 200; }
  @include maxtablet {
    .modal-dialog {
      max-width: calc(100% - 32px); } } }

.modal-role {
  padding: 36px 104px;
  h3 {
    margin-bottom: 16px; }
  .list-blocks {
    column-count: 4;
    column-gap: 32px; }
  .block {
    display: inline-block;
    .title {
      color: $text;
      display: block;
      font-size: 18px;
      line-height: 28px;
      @include font-600();
      margin-bottom: 8px; }
    .list-check {
      margin-bottom: 24px; } }
  &__head {
    display: flex;
    align-items: center; }
  &__img {
    display: block;
    margin-bottom: 28px;
    padding-right: 80px;
    img {
      display: block;
      width: 183px;
      height: auto; } }
  &__title {
    display: block;
    margin-bottom: 28px;
    h2 {
      font-size: 56px;
      line-height: 64px;
      margin-bottom: 0; } }
  @include maxtablet {
    padding: 32px 24px;
    h3 {
      font-size: 20px;
      line-height: 30px; }
    .list-blocks {
      column-count: 1; }
    &__head {
      flex-wrap: wrap; }
    &__title h2 {
      font-size: 24px;
      line-height: 32px; }
    &__img {
      img {
        width: 100%;
        height: auto; } } } }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 100vh;
  background-color: #212121;
  opacity: 0;
  &.show {
    opacity: 0.8!important; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }

.fade.show {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.show {
  display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $border;
  border-radius: 0.25rem;
  box-shadow: 0px 12px 20px rgba(119, 119, 119, 0.2); }

.dropdown-menu.show {
  display: block; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 1rem;
  clear: both;
  font-size: 0.875rem;
  line-height: 1.429em;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover {
    background: #f7f7f7;
    color: $text; }
  &:focus {
    outline: none; }
  &.active {
    background: $border;
    color: $text; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: 0.9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.lity {
  z-index: 2147483001;
  &.lity-iframe {
    .lity-container {
      max-width: 1224px; } } }

.vslider {
  .owl-stage-outer {
    pointer-events: none; }
  .owl-dots {
    position: absolute;
    bottom: 0;
    left: -1.5rem;
    display: flex;
    width: 2px;
    height: 100%;
    flex-direction: column;
    .owl-dot {
      display: block;
      width: 100%;
      height: 100%;
      span {
        display: block;
        width: 100%;
        height: 100%;
        background: #eee; }
      &:focus {
        outline: none; }
      &.active {
        span {
          background: #5B37D4; } } } } }

.owl {
  &-carousel {
    position: relative;
    &.nav-false .owl-nav {
      display: none; }
    &.dots-false .owl-dots {
      display: none; }
    .owl-nav {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 0;
      .disabled {
        opacity: .5; }
      button:focus {
        outline: none !important; }
      .btn-nav,
      button {
        width: 3.2rem;
        height: 3.2rem;
        background-color: #FFF;
        border: 1px solid $primary !important;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 2rem);
        background-size: contain;
        opacity: .8;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: 1; }
        &.left,
        &.owl-prev {
          left: -1.5rem;
          background: #FFF url(../images/ic-long-arrow-right.svg) no-repeat center;
          transform: rotate(-180deg);
          span {
            font-size: 0; } }
        &.right,
        &.owl-next {
          right: -1.5rem;
          background: #FFF url(../images/ic-long-arrow-right.svg) no-repeat center;
          span {
            font-size: 0; } } } } }
  &-dots {
    text-align: center;
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: 2rem;
    left: 0;
    right: 0;
    width: 100%;
    line-height: 0;
    height: 0; }
  &-dot {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.375rem;
    width: auto;
    span {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      background-color: #eee; }
    &:focus {
      outline: none !important; }
    &.active span {
      background-color: $primary; } } }
