/* Footer */
.footer {
  background: #1B1536;
  h1, h2, h3, h4 {
    color: #FFF; }
  color: #FFF;
  &-main {
    padding-top: rem(80); }

  .follow-selftrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(32);
    .follow-socmed {
      display: flex;
      align-items: center;
      a {
        display: block;
        max-width: max-content;
        transition: all .3s ease-in-out;
        &:not(:last-child) {
          margin-right: rem(16); }
        &:hover {
          cursor: pointer;
          transform: translateY(#{rem(-4)}); }
        img {
          width: rem(24);
          height: rem(24); } } } }

  .selfstrow-igbox {
    margin-bottom: rem(72);
    img {
      max-height: rem(240); } }

  .footer-menu {
    background: #1B1536;
    margin-bottom: rem(32);
    &_wrap {
      margin-bottom: rem(32);
      padding-bottom: rem(32);
      border-bottom: 1px solid #544F68; }
    &_left,
    &_right {
      display: flex;
      align-items: center; }
    &_item {
      &:not(:last-child) {
        margin-right: rem(24); }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: #5567C3; } } }
    &_right {
      justify-content: flex-end; }
    &_desc {
      color: #FFF; } }

  &-subs {
    background: #17122D;
    padding: rem(32) 0;
    color: #FFF;
    h4 {
      margin-bottom: 0; }
    .form-subs {
      margin-top: rem(32);
      .form-group {
        display: flex;
        align-items: center;
        .form-control {
          max-width: rem(414);
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.25);
          margin-right: rem(24);
          color: #FFF;
          padding: rem(12) rem(16);
          margin-right: rem(24) !important;
          &::placeholder {
            color: rgba(255, 255, 255, 0.5); } } }
      .gravity-theme {
        form {
          display: flex;
          align-items: center; }

        .gform_fields {
          display: block !important;
          width: rem(414); }
        .ginput_container_email {
          width: 100%;
          max-width: rem(414);
          .form-control {
            width: 100%;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.25);
            margin-right: rem(24);
            color: #FFF; } }
        .gform_footer {
          padding: 0 !important;
          margin: 0;
          margin-left: rem(24) !important;
          button {
            margin-bottom: 0; } } } } }


  &-bottom {
    background: #120E25;
    padding: rem(32) 0;
    font-size: rem(15);
    line-height: rem(24);
    .col-md-4 {
      display: flex;
      justify-content: flex-end; }
    .privpol {
      a {
        color: #FFF; } } } }

@include responsive(1100px, 0) {
  .footer {
    &-menu {
      &_wrap {
        .col-md-7,
        .col-md-5 {
          flex: 0 0 100%;
          max-width: 100%; }
        .col-md-7 {
          margin-bottom: rem(24); } }
      &_right {
        text-align: left;
        justify-content: flex-start !important; } } } }

@include mobile {
  .footer {
    .footer {
      &-menu {
        &_left,
        &_right {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 16px; }
        &_left {
          margin-bottom: rem(30); } } }
    &-bottom {
      text-align: center;
      .col-md-8 {
        order: 2; }
      .col-md-4 {
        order: 1;
        justify-content: center;
        margin-bottom: rem(24); } } }

  .footer-subs .form-subs .gravity-theme .gform_fields {
    width: auto; } }


