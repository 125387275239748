@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str}; }

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args; }

@mixin clear {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin placeholder($color) {
  &::placeholder {
    color: $color; }
  &:-ms-input-placeholder {
    color: $color; }
  &::-ms-input-placeholder {
    color: $color; } }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); }

@mixin transform($translate3d) {
  transform: $translate3d;
  -webkit-transform: $translate3d;
  -moz-transform: $translate3d;
  -o-transform: $translate3d;
  -ms-transform: $translate3d; }

@mixin background($path,$size: auto,$position:0 0,$repeat: no-repeat) {
  background: transparent url(#{$path}) $repeat $position;
  background-size: $size;
  -webkit-background-size: $size;
  -moz-background-size: $size; }
@mixin backgroundSize($val) {
  background-size: $val;
  -webkit-background-size: $val;
  -moz-background-size: $val; }
@mixin bgCover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
@mixin blockFull {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
@mixin lastnomargin {
  &:last-child {
    margin-bottom: 0; } }
@mixin filter($value) {
  -webkit-filter : $value;
  filter: $value; }
@mixin center() {
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  box-align: center; }

@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobile() {
  @include responsive(767px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(1181px) {
    @content; } }
@mixin mtablet() {
  @include responsive(992px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin min-ipadpro() {
  @include responsive(0,1025px) {
    @content; } }
@mixin max-ipadpro() {
  @include responsive(1025px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin tablets() {
  @include responsive(1200px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }
@mixin mac14() {
  @include responsive(1600px,1300px) {
    @content; } }
@mixin imac() {
  @include responsive(1700px,1600px) {
    @content; } }
@mixin imac19() {
  @include responsive(0,1700px) {
    @content; } }

//IE8

@mixin clearfix() {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

@mixin imgBg($padding) {
  @include backgroundSize(cover);
  background-position: center;
  background-color: #000;
  display: block;
  &:before {
    content: "";
    display: block;
    padding: $padding; } }

@mixin bgcenter {
  background-size: cover;
  background-position: center;
  background-color: #F2EEE4;
  background-repeat: no-repeat; }

@mixin absfull() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@mixin bgcover() {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

@mixin btnLink() {
  color: $primary;
  opacity: 1;
  transition: all .3s ease;
  &:hover {
    color: $primary;
    opacity: .75;
    text-decoration: none; } }

@mixin triangle($direction,$width,$height,$color) {
  $w2 : $width/2;
  @if not index("up" "up-right" "right" "down-right" "down" "down-left" "left" "up-left",$direction) {
    @error "Direction must be `up`, `up-right`, `right`, `down-right`,`down`, `down-left`, `left` or `up-left`."; }
  @else if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in the `triangle` mixin."; }
  @else {
    border-style: solid;
    height: 0;
    width: 0;

    @if $direction == "up" {
      border-color: transparent transparent $color;
      border-width: 0 ($w2) $height; }
    @else if $direction == "up-right" {
      border-color: transparent $color transparent transparent;
      border-width: 0 $width $width 0; }
    @else if $direction == "right" {
      border-color: transparent transparent transparent $color;
      border-width: ($height / 2) 0 ($height / 2) $width; }
    @else if $direction == "down-right" {
      border-color: transparent transparent $color;
      border-width: 0 0 $width $width; }
    @else if $direction == "down" {
      border-color: $color transparent transparent;
      border-width: $height ($width / 2) 0; }
    @else if $direction == "down-left" {
      border-color: transparent transparent transparent $color;
      border-width: $width 0 0 $width; }
    @else if $direction == "left" {
      border-color: transparent $color transparent transparent;
      border-width: ($height / 2) $width ($height / 2) 0; }
    @else if $direction == "up-left" {
      border-color: $color transparent transparent;
      border-width: $width $width 0 0; } } }

@mixin fontGenerator($name,$weight,$family) {
  @for $i from 1 through length($family) {
    $src: #{"../fonts/"}#{nth($family, $i)};
    @font-face {
      font-family : $name;
      font-display: swap;
      src         : local($name);
      src         : url("#{$src}.eot");
      src         : url("#{$src}.eot#iefix") format("embedded-opentype"), url("#{$src}.woff2") format("woff2"), url("#{$src}.woff") format("woff"), url("#{$src}.ttf") format("truetype"), url("#{$src}.svg") format("svg");
      font-style  : normal;
      font-weight : nth($weight, $i); } } }

@mixin ellipsis($width: 100%,$display: inline-block) {
  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

// #padding
@mixin padding($a:1,$b:1,$c:1,$d:1) {
  padding: $a*16px $b*16px $c*16px $d*16px; }
@mixin pt($v: 1) {
  padding-top: $v*16px;
  @include maxtablet {
    padding-top: $v*8px; } }
@mixin pb($v: 1) {
  padding-bottom: $v*16px;
  @include maxtablet {
    padding-bottom: $v*8px; } }
@mixin ptb($v: 1) {
  padding-top: $v*16px;
  padding-bottom: $v*16px;
  @include maxtablet {
    padding-top: $v*8px;
    padding-bottom: $v*8px; } }
// #margin
@mixin margin($a:1,$b:1,$c:1,$d:1) {
  margin: $a*16px $b*16px $c*16px $d*16px; }
@mixin mt($v: 1) {
  margin-top: $v*16px;
  @include maxtablet {
    margin-top: $v*8px; } }
@mixin mb($v: 1) {
  margin-bottom: $v*16px;
  @include maxtablet {
    margin-bottom: $v*8px; } }
@mixin mtb($v: 1) {
  margin-top: $v*16px;
  margin-bottom: $v*16px;
  @include maxtablet {
    margin-top: $v*8px;
    margin-bottom: $v*8px; } }

// chevron
@mixin chevronDown($x: 6px, $c: $text) {
  content: "";
  width: 0;
  height: 0;
  border-left: $x solid transparent;
  border-right: $x solid transparent;
  border-top: $x solid $c; }


@function rem($val) {
  $remVal: ($val / 16) + rem;
  @return $remVal; }
