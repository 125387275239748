@import 'cores/mixin';
@import 'cores/vars';


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/custom-plugins';
@import 'components/form';
@import 'components/general';
@import 'components/header';
@import 'components/footer';
@import 'components/content';

/* Pages style */

