/* General */
html {
  scroll-behavior: smooth; }

section:not([class*="-masthead"]):not(.resouces .masthead):not(.masthead-four) {
  @include mtablet {
    padding: rem(80) 0; }
  @include mobile {
    padding: rem(54) 0; } }


.section-heading {
  margin-bottom: rem(40);
  .small-title {
    font-size: rem(16);
    line-height: rem(24);
    color: rgba(27, 21, 54, 0.5);
    @include font-600;
    margin-bottom: rem(16);
    text-transform: uppercase; }
  .big-title {
    font-size: rem(48);
    line-height: rem(56);
    color: #1B1536;
    @include font-title();
    &:last-child {
      margin-bottom: 0; } }

  @include mobile() {
    margin-bottom: rem(30) !important; } }

.section-subheading {
  font-size: rem(20);
  line-height: rem(32); }

.btn {
  padding: rem(20) rem(24);
  text-align: center;
  @include font-600;
  display: block;
  max-width: max-content;
  border: 1px solid transparent;
  color: #FFF;
  transition: all .3s ease-in-out;
  &:focus {
    outline: none !important; }
  &:hover {
    opacity: .9;
    text-decoration: none; }
  &-primary {
    background: $primary;
    border-color: $primary;
    color: #FFF;
    &:hover {
      color: #FFF; } }
  &-secondary {
    background: $secondary;
    border-color: $secondary;
    &:hover {
      color: #FFF;
      background: #E05B11;
      border-color: #E05B11; } }
  &-border {
    background:  rgba(255, 255, 255, 0.1);
    border-color: #FFF;
    &:hover {
      color: $secondary;
      background: #FFF; } }
  &-small {
    padding: rem(12) rem(24); }

  &-arrow {
    color: #5567C3;
    position: relative;
    padding-right: rem(32) !important;
    &:after {
      content: "";
      display: block;
      width: rem(24);
      height: rem(24);
      background: url(../images/ic-arrow-right.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      transition: all .3s ease-in-out; }
    &:hover {
      text-decoration: none;
      &:after {
        transform: translateX(4px); } } }

  &-link {
    color: $primary;
    @include font-600;
    position: relative;
    padding: 0;
    &.back {
      padding-left: rem(32);
      &:before {
        content: "";
        width: rem(24);
        height: rem(24);
        background: url(../images/ic-arrow-left-primary.png) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s ease-in-out; } }
    &:hover {
      color: $primary;
      text-decoration: none;
      &:before {
        transform: translateX(-4px); } } }
  &.nopadding {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0; }
  &-back {
    color: #FFF;
    position: relative;
    padding-left: rem(32) !important;
    &:after {
      content: "";
      display: block;
      width: rem(24);
      height: rem(24);
      background: url(../images/ic-arrow-left-white.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .3s ease-in-out; }
    &:hover {
      text-decoration: none;
      color: #FFF;
      &:after {
        transform: translateX(-4px); } } } }
.tag {
  font-size: rem(16);
  line-height: rem(24);
  border: 1px solid #1B1536;
  border-radius: 50px;
  padding: 0 rem(12);
  text-transform: uppercase;
  color: rgba(27, 21, 54, 0.75);
  display: block;
  max-width: max-content;
  &.small {
    font-size: rem(16);
    line-height: rem(24);
    padding: 0 8px;
    text-transform: capitalize; }

  &-orange {
    background: $secondary;
    color: #FFF;
    border-color: $secondary; }
  @include min-ipadpro() {
    font-size: rem(14);
    line-height: 1.5em; } }


.sylcode {
  color: $secondary !important;
  @include font-600; }

.pb {
  &-0 {
    padding-bottom: 0 !important; }
  &-1 {
    padding-bottom: rem(40); }
  &-2 {
    padding-bottom: rem(80); } }
.mb {
  &-0 {
    margin-bottom: 0 !important; }
  &-1 {
    margin-bottom: rem(40); }
  &-2 {
    margin-bottom: rem(80); } }

// Text Marquee

@keyframes scroll-x {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(calc(-100% - 8px)); } }

.text-marqueewrap {
  display: flex;
  align-items: center;
  overflow: hidden;
  user-select: none;
  padding-bottom: rem(24);
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: rem(24); }
  //mask-image: linear-gradient(var(--mask-direction, to right), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))
  span {
    display: grid;
    border-radius: 6px;
    font-size: rem(36);
    line-height: rem(40);
    color: #FFF;
    opacity: .3;
    white-space: nowrap;
    @include font-700;
    transition: all .3s ease-in-out;
    &:hover {
      cursor: pointer;
      opacity: 1; }
    // a
    //   display: flex
    // img
    //   width: auto
 }    //   max-height: 56px

  .text-marquee {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: rem(24);
    min-width: 100%;
    animation: scroll-x var(--duration, 60s) linear infinite;
    &:not(:last-child) {
 } }      // margin-right: rem(24)

  &.marquee--reverse,
  &:nth-child(1) {
    --duration: 70s; }
  &:nth-child(2) {
    --duration: 80s; }
  &:nth-child(even) {
    .text-marquee {
      animation-direction: reverse;
      &:not(:last-child) {
        margin-right: rem(40); }
 } }      // animation-delay: -3s

  // @media (prefers-reduced-motion: reduce)
  //   .marquee__group
  //     animation-play-state: paused

  @include max-ipadpro() {
    .text-marquee {
      span {
        font-size: rem(24);
        line-height: 1.3em; } } } }

// Masthea
.masthead {
  &-typetwo {
    margin-top: rem(76);
    padding: rem(120) 0 rem(80);
    .tag {
      margin-bottom: rem(16); }
    h1 {
      font-size: rem(96);
      line-height: rem(96);
      color: #1B1536;
      max-width: rem(890);
      @include font-700;
      margin-bottom: rem(40); }
    p {
      font-size: rem(24);
      line-height: rem(32);
      color: rgba(27, 21, 54, 0.75); }
    .masthead-img {
      width: 100%;
      padding: 22.24% 0;
      position: relative;
      margin: rem(80) 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0; } } }
  &-typethree {
    padding: rem(196) 0 rem(80);
    background-image: url(../images/zodiac-acsen.png);
    background-color: $primary;
    background-size: auto 90%;
    background-position: 128% bottom;
    background-repeat: no-repeat;
    color: #FFF;
    .btn-back {
      margin-bottom: rem(16); }
    .tag {
      margin-bottom: rem(16);
      color: #FFF;
      border-color: #FFF; }
    h1 {
      font-size: rem(96);
      line-height: rem(96);
      color: #FFF;
      @include font-700;
      margin-bottom: rem(40); }
    p {
      font-size: rem(24);
      line-height: rem(32);
      color: #FFF; } }
  &-four {
    .content {
      position: relative;
      &:before {
        content: "";
        width: 100%;
        max-width: 78.056vw;
        // height: rem(48)
        height: 100%;
        background: #FFF;
        position: absolute;
        top: rem(-160);
        left: 0; } }
    .masthead-img {
      width: 100%;
      img {
        width: 100%;
        height: auto; } }
    .content {
      padding: rem(48) 0 rem(24);
      background: #FFF;
      .col-lg-9 {
        background: #fff;
        margin-top: rem(-160); }
      .tag {
        margin-bottom: rem(16); }
      .large {
        margin-bottom: rem(40); }
      p {
        font-size: rem(24);
        line-height: rem(32); } } }
  @include max-ipadpro() {
    &-typetwo {
      h1 {
        font-size: rem(72) !important;
        line-height: 1.250em;
        max-width: 100%; }
      .masthead-img {
        margin: rem(60) 0 0; } } }

  @include mobile() {
    &-typetwo {
      h1 {
        font-size: rem(56) !important; }
      .masthead-img {
        margin: rem(46) 0 0; } } } }

.global-faq {
  padding: rem(120) 0;
  background: $primary;
  .section-heading {
    .big-title {
      color: #FFF; } } }
.faq-banner {
  background-color: $secondary;
  background-image: url(../images/zodiac-acsen.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: rem(560);
  padding: rem(40);
  display: flexf;
  justify-content: space-between;
  margin-top: rem(80);
  .row {
    align-items: center;
    justify-content: space-between;
    .col-md-6 {
      &:last-child {
        display: flex;
        justify-content: flex-end; } } }
  h4 {
    color: #FFF;
    margin-bottom: 0; }
  .btn + .btn {
    margin-left: rem(16); } }

.accordion {
  &-group {
    &:not(:last-child) {
      margin-bottom: rem(80); }
    h2 {
      margin-bottom: rem(24); }
    .faq-accordion {
      * {
        transition: all .3s ease-in-out; }
      .panel {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: rem(24);
        transition: all .3s ease-in-out;
        &:not(:last-child) {
          margin-bottom: rem(16); }
        &-heading {
          h4 {
            padding-bottom: 0;
            transition: all .3s ease-in-out;
            margin-bottom: 0;
            a {
              color: #FFF;
              &:hover {
                text-decoration: none; } } } }
        &-collapse {
          padding-top: rem(16);
          padding-bottom: 0;
          transition: all .3s ease-in-out;
          &.collapsing {
            .panel-body {
              opacity: 0;
              visibility: hidden;
              max-height: 0;
              padding: 0;
              margin: 0; } }
          &.show {} }
        &-title {
          a {
            width: 100%;
            padding-right: rem(64);
            display: block;
            position: relative;
            color: $primary;
            font-size: rem(20);
            line-height: rem(28);
            &:after {
              content: "";
              position: absolute;
              top: calc(50% - 1px);
              right: 0;
              width: rem(24);
              height: 2px;
              background: #FFF;
              transition: all .3s ease-in-out; }
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 11.5px);
              right: rem(11.5);
              width: 2px;
              height: rem(24);
              background: #FFF;
              transition: all .3s ease-in-out;
              transform: rotate(90deg); }

            &.collapsed {
              color: #FFF;
              &:before {
                content: "";
                transform: rotate(0); } } } }
        &-body {
          p {
            color: #FFF; } } } }
    .career-accordion {
      .panel {
        border-bottom: 1px solid rgba(27, 21, 54, 0.2);
        padding-bottom: rem(24);
        transition: all .3s ease-in-out;
        &:not(:last-child) {
          margin-bottom: rem(16); }
        &-heading {
          h4 {
            padding-bottom: 0;
            transition: all .3s ease-in-out;
            margin-bottom: 0;
            a {
              color: $primary;
              &:hover {
                text-decoration: none; } } } }
        &-collapse {
          padding-top: rem(16);
          padding-bottom: 0;
          transition: all .3s ease-in-out;
          &.show {} }
        &-title {
          a {
            width: 100%;
            padding-right: rem(64);
            display: block;
            position: relative;
            color: $primary;
            font-size: rem(20);
            line-height: rem(28);
            &:after {
              content: "";
              position: absolute;
              top: calc(50% - 1px);
              right: 0;
              width: rem(24);
              height: 2px;
              background: #1B1536;
              transition: all .3s ease-in-out; }
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 11.5px);
              right: rem(11.5);
              width: 2px;
              height: rem(24);
              background: #1B1536;
              transition: all .3s ease-in-out;
              transform: rotate(90deg); }

            &.collapsed {
              color: $primary;
              &:before {
                content: "";
                transform: rotate(0); } } } }
        &-body {
          p {
            color: rgba(27, 21, 54, 0.75); }
          .custom-list {
            margin-bottom: rem(24); } } } } } }

.modalDownloadPros {
  .modal-content {
    max-width: rem(552);
    border: 1px solid rgba(27, 21, 54, 0.1);
    box-shadow: 0px 1px 6px rgba(27, 21, 54, 0.1), 0px 8px 32px rgba(27, 21, 54, 0.15);
    border-radius: 0;
    padding: rem(24);
    .form-action.text-right {
      display: flex;
      justify-content: flex-end; }
    h4 {
      color: $primary; }
    label {
      color: $primary; } } }

ul.custom-list {
  li {
    padding-left: rem(32);
    position: relative;
    &:not(:last-child) {
      margin-bottom: rem(16); }
    &:before {
      content: "";
      width: rem(24);
      height: rem(24);
      background: url(../images/custom-list-bullet.png) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0; } } }

// Section CTA
.section-cta {
  padding: rem(120) 0;
  background: url(../images/zodiac-aksen.svg) no-repeat;
  background-color: $primary;
  background-position: center rem(62);
  color: #fff;
  text-align: center;
  .section-heading {
    .big-title {
      color: #FFF; } }
  p {
    max-width: rem(656);
    margin: 0 auto rem(16);
    font-size: rem(20);
    line-height: rem(32); }
  .btn-secondary {
    margin: 0 auto; } }


/* == WordPress WYSIWYG Editor Styles == */

.entry-content img {
  margin: 0 0 1.5em 0; }

.alignleft, img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
  @include mobile {
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 16px;
    object-fit: contain; }
  &:after {
    content: "";
    clear: both;
    display: table; } }

.alignright, img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
  @include mobile {
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 16px;
    object-fit: contain; }
  &:after {
    content: "";
    clear: both;
    display: table; } }

.aligncenter, img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
  @include mobile {
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 16px;
    object-fit: contain; } }

.alignnone, img.alignnone {}

article .leftright {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(40);
  gap: rem(24);
  & > * {
    flex: 0 0 50%;
    max-width: 50%; } }

/* not sure about this one */

.wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;

  img {
    border: 0 none;
    padding: 0;
    margin: 0; }

  p.wp-caption-text {
    line-height: 1.5;
    font-size: 10px;
    margin: 0; } }

.wp-smiley {
  margin: 0 !important;
  max-height: 1em; }

blockquote {
  padding: rem(88) 0;
  border-top: 1px solid rgba(27, 21, 54, 0.1);
  border-bottom: 1px solid rgba(27, 21, 54, 0.1);
  &.left {
    margin-right: 20px;
    text-align: right;
    margin-left: 0;
    width: 33%;
    float: left; }

  &.right {
    margin-left: 20px;
    text-align: left;
    margin-right: 0;
    width: 33%;
    float: right; } }

.gallery {
  dl, dt, dd {}

  dl {
    a, img {} } }

.gallery-caption, .size-full, .size-large, .size-medium, .size-thumbnail {}
