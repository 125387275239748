@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce3;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce2 {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(-4px); }
  50% {
    transform: scale(1.05,.95) translateX(6px); }
  57% {
    transform: scale(1,1) translateX(-2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@keyframes bounce3 {
  0% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0.375rem); } }

@mixin pulse($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 14px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }

@mixin pulseBig($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 28px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }

@mixin pulse2() {
  box-shadow: 0 0 0 rgba(0,95,191, 0.8);
  animation: pulse2 2s infinite; }

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  70% {
    box-shadow: 0 0 0 16px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }


@mixin pulseSVG() {
  animation: pulseSvg 2s infinite; }

@keyframes pulseSvg {
  0% {
    r: 6;
    opacity: 0.3; }
  70% {
    r: 12;
    opacity: 0.3; }
  80% {
    r: 12;
    opacity: 0.2; }
  90% {
    r: 12;
    opacity: 0.1; }
  100% {
    r: 12;
    opacity: 0; } }
@include maxtablet {
  @keyframes pulseSvg {
    0% {
      r: 12;
      opacity: 0.3; }
    70% {
      r: 18;
      opacity: 0.3; }
    80% {
      r: 18;
      opacity: 0.2; }
    90% {
      r: 18;
      opacity: 0.1; }
    100% {
      r: 18;
      opacity: 0; } } }

@mixin dash($duration) {
  stroke-dasharray: 1000;
  animation: dash $duration linear; }
@keyframes dash {
  from {
    stroke-dashoffset: 1000; }
  to {
    stroke-dashoffset: 0; } }

@mixin dash2($duration) {
  stroke-dasharray: 1500;
  animation: dash2 $duration linear; }
@keyframes dash2 {
  from {
    stroke-dashoffset: 1500; }
  to {
    stroke-dashoffset: 0; } }


.slideOutDown {
  animation-name: slideOutDown; }

@keyframes  slideOutDown {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1.25); } }

.comeOut {
  animation-name: comeOut; }

@keyframes  comeOut {
  0% {
    opacity: 1;
    transform: scale(.5); }
  100% {
    opacity: 0;
    transform: scale(1); } }


@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }


@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible; }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@mixin progressBar($duration) {
  animation: progressBar $duration linear; }
@keyframes progressBar {
  0% {
    max-width: 0%; }
  100% {
    max-width: 100%; } }

@keyframes d-headline {
  0% {
    max-width: 0%; }
  10% {
    max-width: 0%; }
  100% {
    max-width: 100%; } }
