/* Form */

label {
  display: block;
  @include font-700();
  margin: 0 0 4px;
  color: #121B21; }

.form-control {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5em;
  @include font-400();
  color: $text;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #E5E6E6;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: rgba(176, 179, 181, 1); }
  &:focus {
    border-color: $primary;
    // box-shadow: 0px 0px 0px 1px $primary
    box-shadow: none !important;
    outline: none !important; } }
textarea.form-control {
  min-height: 10rem;
  height: auto;
  resize: none; }

.help-text {
  color: $text-light;
  display: block;
  margin-bottom: 4px;
  @include font-400(); }

.button-contact {
  width: 100%;
  background: $white;
  border: 2px solid $text;
  padding: 16px;
  color: $text;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #1C1C1C;
    color: #FFF; } }


select.select {
  display: block;
  width: 100%;
  height: 2.5rem;
  border: 1px solid $border;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  opacity: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding: 0.5rem 0 0.5rem 2rem;
  color-adjust: exact;
  &-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    &:checked ~ .custom-control-label::before {
      color: $white;
      border-color: $primary;
      background-color: $primary; }
    &:focus ~ .custom-control-label::before {
      border-color: $primary; }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $primary; }
    &:not(:disabled):active ~ .custom-control-label::before {
      color: $white;
      background-color: $primary;
      border-color: $primary; }
    &[disabled] ~ .custom-control-label,
    &:disabled ~ .custom-control-label {
      color: $text-light; }
    &[disabled] ~ .custom-control-label::before,
    &:disabled ~ .custom-control-label::before {
      background-color: $text-light; } }

  &-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0.125rem;
      left: -2rem;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      pointer-events: none;
      content: "";
      background-color: $white;
      border: 1px solid $border; }
    &::after {
      position: absolute;
      top: 0.125rem;
      left: -2rem;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      content: "";
      background-repeat: no-repeat;
      background-size: 0.75rem 0.75rem;
      background-position: center; } }

  &-inline {
    display: inline-block;
    &+ .custom-control-inline {
      margin-left: 1rem; } } }

.custom-checkbox {
  .custom-control-label::before {
    border-radius: 2px; }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(../images/ic-check.svg); }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    opacity: 0.5; } }

.custom-checkbox-alt {
  .custom-control-label::before {
    border-radius: 2px; }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(../images/ic-check-alt.svg); }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    opacity: 0.5; } }

.custom-radio {
  .custom-control-label::before {
    border-radius: 50%; }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    opacity: 0.5; } }

.form-group {
  padding-bottom: 1.333rem; //1.5rem
  &:last-child {
    padding-bottom: 0; }
  &.mb,
  &.mb:last-child {
    padding-bottom: 1rem; }
  &.mt,
  &.mt:last-child {
    padding-top: 1rem; } }


.form-action {
  padding-top: .5rem;
  padding-bottom: 1.5rem;
  .btn:not(.btn-block) + .btn:not(.btn-block) {
    margin-left: 1rem; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }
  &:last-child {
    padding-bottom: 0; }
  display: flex;
  justify-content: flex-end; }

.dropdown.bootstrap-select {
  position: relative;
  color: inherit;
  select {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0.5px;
      height: 100%;
      padding: 0;
      opacity: 0;
      border: none;
      visibility: hidden; }
  .btn.dropdown-toggle {
      cursor: pointer;
      background: #fff;
      border: 1px solid #E2E2E2;	border-radius: 4px;
      font-size: 14px;
      letter-spacing: -0.04px;
      line-height: 46px;
      font-weight: 200;
      width: 100%;
      text-align: left;
      padding: 0 23px 0 12px;
      outline: none !important;
      color: inherit;
      max-width: 100%;
      border-radius: 0;
      &:after {
          content: "";
          background: url(../images/ic-carret-down.svg) no-repeat center;
          background-size: contain;
          width: rem(24);
          height: rem(24);
          top: calc(50% - 0.75rem);
          right: 10px;
          position: absolute;
          border: none; } }
  div.dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      text-align: left;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #AFAFAF;	border-radius: 4px;
      margin: 2px 0 0;
      //padding: 10px
      width: 100%;
      min-width: 100px;
      //transform: none !important
      box-shadow: 0 16px 40px 0 rgba(0,0,0,0.25);
      a {
          display: block;
          padding: 10px 12px;
          color: #485460;
          font-weight: 400;
          cursor: pointer;
          font-size: 14px;	line-height: 20px; }
      li {
          position: relative;
          .fa {
              display: none; }
          //a.fa
          //  display: block
          //  &:before
          //      display: none
          &:hover {
              a {
                  background: $secondary;
                  text-decoration: none;
                  color: #FFF; } }
          &.selected,&.active {
              a {
                  background: $secondary; } }
          &:not(:last-child) {
 } }              //border-bottom: 1px solid #D8D8D7
      &.show {
          display: block; } }
  &.show {
      .bttn {
          border-color: #1565BE; } } }


// GRAVITY FORM
body * {
  outline: none !important; }

body .gform_wrapper {
  margin: 0 !important; }

body .form-group {
  padding: 0 0 1rem;
  margin: 0; }

body .form-group .ginput_container {
  margin: 0 !important;
  padding: 0 !important; }

body .form-group input {
  margin: 0 !important;
  display: block;
  height: auto;
  width: 100% !important;
  font-size: 1.125rem;
  line-height: 1.5em;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #C9CED7;
  padding: 1rem 0; }

body .form-group textarea {
  margin: 0 !important;
  display: block;
  height: 168px;
  width: 100% !important;
  font-size: 1.125rem;
  line-height: 1.5em;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #C9CED7;
  padding: 1rem 0; }

body .gform_footer {
  margin: 0 !important;
  padding: 0.5rem 0 0 !important;
  text-align: right; }

body .gform_button {
  display: inline-block;
  @include font-400();
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 1rem;
  font-size: 1rem;
  line-height: 1.5em;
  border-radius: 0.25rem;
  transition: all .3s ease-in-out;
  color: $white;
  background-color: $primary;
  border-color: $primary;
  margin: 0;
  &:hover {
    color: $white;
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%); }
  &:focus, &.focus {
    outline: none;
    box-shadow: none;
    color: $white;
    background-color: darken($primary, 8%);
    border-color: darken($primary, 8%); }
  &.disabled, &:disabled {
    pointer-events: none;
    color: $text-light;
    background-color: $white;
    border-color: $border;
    opacity: 1; }
  &:not(:disabled):not(.disabled) {
    cursor: pointer; }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $white;
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%); } }

.gform_wrapper {
  .gfield_required {
    display: none !important; }
  .button-contact {
    @extend .btn;
    @extend .btn-secondary; } }
.gform_wrapper .gfield_validation_message,
.gform_wrapper .validation_message {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    font-size: rem(14) !important;
    line-height: 1em !important;
    background: transparent !important;
    border: 0 !important; }

.gform_footer {
  position: relative; }
.gform_ajax_spinner {
  position: absolute;
  top: 0;
  left: 100%;
  padding: 12px; }

// ERRORS
body {
  .validation_error,
  .gform_validation_errors {
    display: none; }
  li.gfield.gfield_error,
  li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    border: 0;
    padding: 0;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    background: transparent; }
  .validation_message {
      padding: 0.5rem 0 1rem;
      color: $danger;
      background: transparent;
      @include font-400();
      font-weight: normal; } }

// gform success
.gform_confirmation_message {
  position: relative;
  padding: 1.5rem 2rem 1.5rem 3.5rem;
  background: #d1e7dd;
  color: #0f5132;
  border-radius: 2px;
  margin: 24px 0;
  font-size: 1rem;
  line-height: 1.5em;
  &:before {
    content: "";
    display: block;
    width: 1.5em;
    height: 1.5em;
    background: transparent url(../images/ic-correct.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem; } }
.gform_wrapper .gform_validation_errors>h2 .gform-icon {
  left: -34px !important; }
.gform_wrapper .gfield_validation_message, .gform_wrapper .validation_message {
  padding: 8px !important;
  padding-top: 8px !important; }
