/* Fonts */

@include fontGenerator("SFProTextRegular",(400),(SFProTextRegular));
@include fontGenerator("SFProTextSemibold",(500),(SFProTextSemibold));
@include fontGenerator("SFProTextBold",(700),(SFProTextBold));

@include fontGenerator("SF Pro Display",(400, 500, 700),(SFProDisplay-Regular,SFProDisplay-Medium,SFProDisplay-Bold));



@mixin font-title {
  font-family: "SF Pro Display", sans-serif; }

@mixin font-400 {
  font-family: "SFProTextRegular", sans-serif;
  font-weight: 400; }

@mixin font-600 {
  font-family: "SFProTextSemibold", sans-serif;
  font-weight: 600; }

@mixin font-700 {
  font-family: "SFProTextBold", sans-serif;
  font-weight: 700; }





