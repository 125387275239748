/* Contents */


// Homepage
.homepage {
  &-masthead {
    position: relative;
    // padding-top: rem(840)
    min-height: rem(840);
    height: 100vh;
    .bg-masthead {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }
    .masthead-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #FFF;
      h1 {
        font-size: rem(96);
        line-height: rem(96);
        margin-bottom: rem(24);
        color: #FFF; }
      p {
        max-width: rem(850);
        margin: 0 auto;
        font-size: rem(24);
        line-height: rem(32); } } }

  &-feateuredin {
    padding: rem(48) 0;
    .section-title {
      margin-bottom: rem(16); }
    .featured-parenter {
      .wrapped-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(27, 21, 54, 0.1); } }
      .partner-logo {
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);
        padding: rem(48);
        border-right: 1px solid rgba(27, 21, 54, 0.1);
        // border-bottom: 1px solid rgba(27, 21, 54, 0.1)
        display: flex;
        justify-content: center;
        &:nth-child(4n) {
          border-right: 0; }
        &:nth-last-child(-n+4) {
          border-bottom: 0; }
        &:last-child {
 }          // border-right: 0
        img {
          height: rem(48);
          object-fit: contain; } } } }

  &-whoweare {
    padding: rem(120) 0 rem(96);
    article {
      margin-top: rem(42);
      font-size: rem(20);
      line-height: rem(32); }
    .whoweare-countdown {
      border-top: 1px solid $border;
      margin-top: rem(64);
      padding-top: rem(64);
      .col-4 {
        margin-bottom: rem(24); }
      .val-card {
        .value {
          @include font-700;
          font-size: rem(48);
          line-height: rem(56);
          color: #1B1536; }
        span {
          color: #1B1536;
          display: block; } } } }

  &-ouracademy {
    padding: rem(120) 0 rem(40);
    position: relative;
    &:before {
      content: "";
      width: calc(100% - 3.889vw);
      height: calc(100% + 5.556vw);
      background: #F6F7F9;
      position: absolute;
      top: 0;
      left: 0; }

    .container {
      position: relative;
      z-index: 3; }

    .section-heading {
      margin-bottom: rem(64); }
    .section-subheading {
      margin-top: rem(42); }
    .ouracademy {
      &-card {
        .thumbnail {
          width: 100%;
          padding-top: 81.63%;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0; } }
        .text {
          padding: rem(24);
          background: #FFF;
          h4 {
            margin-bottom: rem(8);
            a {
              color: #1B1536;
              text-decoration: none;
              &:hover {
                opacity: .8; } } }
          .btn-arrow {
            &:after {
              top: -2px; } } } } } }
  &-ourstudent {
    padding: rem(200) 0 rem(120);
    background: #1B1536;
    .section-heading {
      margin-bottom: rem(64);
      .small-title,
      .big-title {
        color: #FFF; } } }

  &-founders {
    padding: rem(88) 0;
    background-size: auto 100%;
    background-position: right top;
    background-repeat: no-repeat;
    @include responsive(0, 1921px) {
      max-width: rem(1248);
      margin: 0 auto; } }

  &-teacher {
    padding: rem(120) 0;
    & > .row {
      align-items: flex-start; }
    .section {
      &-heading,
      &-subheading {
        margin-bottom: rem(64); }
      &-subheading {
        margin-top: rem(42); } }

    .seemore {
      display: flex;
      justify-content: center;
      margin-top: rem(24);
      .btn-border {
        border-color: rgba(27, 21, 54, 0.25);
        color: rgba(85, 103, 195, 1); } } }

  &-joinzoom {
    padding: rem(120) 0;
    background: url(../images/zodiac-aksen.svg) no-repeat;
    background-color: rgba(31, 24, 62, 1);
    background-position: center rem(62);
    color: #fff;
    text-align: center;
    .section-heading {
      .big-title {
        color: #FFF; } }
    p {
      max-width: rem(656);
      margin: 0 auto rem(16);
      font-size: rem(20);
      line-height: rem(32); }
    .btn-secondary {
      margin: 0 auto; } } }

.resources-slider {
  &:not(.owl-carousel) {
    // opacity: 0
    // visibility: hidden
    // max-height: 0
    display: flex;
    gap: 16;
    align-items: flex-start; } }

.free-resources {
  padding: rem(120) 0;
  background: #F6F7F9;
  & .container > .row {
    margin-bottom: rem(64); }
  .section-heading,
  .section-subheading {
    margin-bottom: 0; }
  .section-subheading {
    margin-top: rem(42); } }

.youtube-resources {
  &:not(:last-child) {
    margin-bottom: rem(40); }
  .title {
    display: flex;
    align-items: center;
    margin-bottom: rem(16);
    h5 {
      font-size: rem(20);
      line-height: rem(28);
      color: #1B1536;
      margin-bottom: 0;
      margin-right: rem(24);
      .ic {
        width: rem(24);
        height: rem(24);
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        margin-top: -4px; } }
    .playall {
      position: relative;
      padding-right: rem(32);
      @include font-600;
      &:after {
        content: "";
        width: rem(24);
        height: rem(24);
        position: absolute;
        top: 0;
        right: 0;
        background: url(../images/ic-triangle-play.svg) no-repeat center;
        background-size: contain;
        transition: all .3s ease-in-out; }
      &:hover {
        text-decoration: none;
        &:after {
          transform: translateX(-4); } } } } }

.yt-card {
  width: 100%;
  max-width: rem(288);
  .thumbnail {
    width: 100%;
    padding-top: rem(160);
    position: relative;
    margin-bottom: rem(8);
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; } }
  .text {
    .title {
      text-decoration: none;
      color: $primary;
      @include font-600;
      margin-bottom: rem(8);
      &:hover {
        color: #5567C3; } }
    .name {
      color: #777777;
      font-size: rem(14);
      line-height: rem(20); } } }

.igfb-card {
  width: 100%;
  min-width: rem(225);
  .thumbnail {
    width: 100%;
    padding: 53.5% 0;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; } } }

.tiktok-card {
  width: 100%;
  min-width: rem(225);
  .thumbnail {
    width: 100%;
    padding: 71.3% 0;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center; } } }

$vcard-count: 10;

.videocard-wrap {
  .col-lg-3 {
    margin-bottom: rem(40); }
  [class^=col-]:nth-child(2n+0) {
    transform: translateY(188px); }
  @for $i from 1 through $vcard-count {
    .row .col-lg-3:nth-child(#{$i}) .video-card .btn-play {
      $delay-multiplier: random(5); // perkalian random untuk animation delay
      animation-delay: $delay-multiplier * 0.3s; } } }

.video-card {
  .thumbnail {
    width: 100%;
    padding-top: rem(200);
    position: relative;
    margin-bottom: rem(24);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0; } }
  .text {
    b {
      color: #1B1536; } }
  .btn-play {
    position: absolute;
    right: rem(20);
    bottom: rem(20);
    width: rem(40);
    height: rem(40);
    border-radius: 50px;
    background: url(../images/ic-play.png) no-repeat center;
    background-size: contain;
    &:hover {
      @include pulse2; } } }


// Study
.study {
  .masthead-typetwo {
    padding-bottom: 0;
    .masthead-img {
      margin-bottom: 0; } }
  .masthead-video {
    position: relative;
    height: 100vh;
    .masthead-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }
    .masthead-content {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .tag {
        color: #FFF;
        border-color: #FFF;
        margin-bottom: rem(16); }
      h1 {
        color: #FFF;
        margin-bottom: rem(50);
        max-width: rem(808);
        font-size: rem(96);
        line-height: rem(96); }
      .btn-play {
        position: relative;
        padding-left: rem(72);
        &:hover {
          color: #FFF; }
        &:before {
          content: "";
          position: absolute;
          right: rem(20);
          bottom: rem(20);
          width: rem(40);
          height: rem(40);
          border-radius: 50px;
          background: url(../images/ic-play.png) no-repeat center;
          background-size: contain;
          @include pulse2;
          position: absolute;
          top: rem(10);
          left: rem(10); } } } }
  .compehersive {
    padding: rem(120) 0;
    background: #F6F7F9;
    .section-heading {
      margin-bottom: rem(40); }
    .row.no-gutter {
      .col-md-6 {
        &:last-child:not(:first-child) {
          .card-index {
            border-left: 0; } } } }
    .card-index {
      padding: rem(40);
      border: 1px solid rgba(27, 21, 54, 0.1);
      background: #FFF;
      height: 100%;
      .tag {
        margin-bottom: rem(24);
        @include font-600; }
      ul {
        li {
          padding: rem(16) 0;
          border-bottom: 1px solid rgba(27, 21, 54, 0.1);
          span {
            @include font-600;
            color: $secondary;
            display: block;
            max-width: max-content; }
          h4 {
            color: $primary;
            margin-bottom: 0; } } } } }
  .programmelist {
    padding: rem(120) 0;
    background: $primary;
    .section-heading {
      margin-bottom: rem(40);
      h2 {
        font-size: rem(48);
        line-height: rem(56);
        @include font-700;
        color: #FFF;
        margin-bottom: 0; } }
    .programme-item {
      &:not(:last-child) {
        margin-bottom: rem(64); }
      .col-md-6:nth-child(2n) {
        .programme-card {
          @include mintablet {
            border-left: 0; }
          @include min-ipadpro {
            border-top: 0; } } }
      .titlelevel {
        display: flex;
        align-items: center;
        margin-bottom: rem(24);
        h3 {
          color: #FFF;
          margin-bottom: 0; }
        .level {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-left: rem(16);
          span {
            display: block;
            flex: 0 0 rem(24);
            max-width: rem(24);
            height: 4px;
            background: #FFF;
            &.active {
              background: $secondary; } } } } }
    .programme-card {
      padding: rem(40);
      background: #FFF;
      border: 1px solid rgba(27, 21, 54, 0.1);
      height: 100%;
      display: flex;
      flex-direction: column;
      h4 {
        color: $primary; }
      .prerequrie {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: rem(24); }
      label {
        @include font-400;
        margin-bottom: 0;
        color: rgba(27, 21, 54, 0.75); }
      span:not(.pill) {
        color: $primary;
        @include font-600; }
      span.pill {
        background: #FFF;
        border: 1px solid $primary;
        border-radius: 50px;
        font-size: rem(14);
        line-height: rem(20);
        @include font-600;
        color: $primary;
        text-align: center;
        padding: 0 8px; }
      .price-box {
        padding: rem(16);
        background: #F6F7F9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        .price {
          font-size: rem(32);
          line-height: rem(40);
          @include font-700();
          color: $primary; }
        .btn-arrow {
          padding-top: 0;
          padding-bottom: 0;
          color: #5567C3;
          &:after {
            top: 0; } } } } }
  .program-detail {}

  .program-desc {
    padding: rem(120) 0;
    .course-list {
      column-count: 2;
      gap: rem(24); }
    hr {
      display: block;
      margin: rem(80) 0; } }

  .program-reasonate {
    padding: rem(80) 0 rem(56);
    background: #F6F7F9;
    .col-md-4 {
      margin-bottom: rem(24); }
    .reasonate-card {
      padding: rem(24);
      border: 1px solid rgba(8, 14, 72, 0.25);
      background: #FFF;
      position: relative;
      overflow: hidden;
      height: 100%;
      h3 {
        max-width: rem(240);
        border-bottom: 1px solid rgba(27, 21, 54, 0.25);
        padding-bottom: rem(24);
        margin-bottom: rem(24); }
      .number {
        opacity: .3;
        position: absolute;
        right: rem(-16);
        top: rem(-46);
        font-size: rem(120);
        line-height: rem(143);
        @include font-700;
        color: transparent;
        // text-shadow: 0px 0px #DBDBDB
        -webkit-text-fill-color: #FFF;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #DBDBDB;
        // @supports (-webkit-text-stroke: 1px black)
        //   -webkit-text-stroke: 1px rgba(8, 14, 72, 0.25)
 }        //   -webkit-text-fill-color: rgba(8, 14, 72, 0.25)
      .desc {
        b {
          color: #1B1536; } } } }

  .program-classworks {
    padding: rem(80) 0;
    .section-heading,
    .section-subheading {
      margin-bottom: rem(40); }
    .section-subheading {
      p {
        font-size: rem(18);
        line-height: rem(28); } } }

  .classworks-list {
    .col-md-6 {
      margin-bottom: rem(40); } }

  .classworks-card {
    .thumbnail {
      margin-bottom: rem(24);
      img {
        width: 100%;
        height: auto;
        max-height: rem(320); } }
    .content {
      h4 {
        color: $primary;
        position: relative;
        margin-bottom: rem(24);
        &:after {
          content: "";
          width: rem(240);
          height: 1px;
          background: rgba(27, 21, 54, 0.25);
          position: absolute;
          left: 0;
          bottom: -8px; } } } }

  .bottom-img {
    img {
      width: 100%;
      height: auto; } }

  .profile-detail {
    padding-top: rem(196);
    position: relative;
    .bg-aksen {
      content: "";
      width: 100%;
      // min-height: rem(612)
      // height: rem(612) //42.500vw
      background: #F6F7F9;
      position: absolute;
      top: 0;
      left: 0; }
    .container {
      position: relative;
      z-index: 1; }
    .back {
      display: block;
      max-width: max-content;
      margin-bottom: rem(40); }
    .leader-photo {
      width: 100%;
      max-width: rem(400);
      padding: 76.54% 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } }
    .box-detail {
      padding-left: rem(60);
      .profile-top {
        padding-bottom: rem(104); }
      .tag {
        margin-bottom: rem(16); }
      .position {
        font-size: rem(24);
        line-height: rem(32);
        color: #1B1536;
        margin-bottom: rem(40); }
      .profile-socmed {
        display: flex;
        align-items: center;
        gap: rem(16);
        a {
          transition: all .3s ease-in-out;
          &:hover {
            transform: translateY(-4px); } } } }
    .welcome-remark {
      padding-top: rem(80); //rem(184)
      article {
        margin-bottom: rem(40);
        h2 {
          font-size: rem(48);
          line-height: rem(56);
          margin-top: 0;
          margin-bottom: rem(24); } } } }

  .course-sylabus {
    padding: rem(120) 0;
    background: $primary;
    .section-heading {
      margin-bottom: rem(40);
      h2 {
        @include font-700;
        color: $white; } }
    .sylabus-wrap {
      display: flex;
      flex-wrap: wrap;
      column-count: 2;
      .col-lg-6,
      .col-md-6 {
        &:nth-child(2n) {
          .cs-card {
            border-left: 0; } } }
      .cs-card {
        padding: rem(40);
        background: $white;
        border: 1px solid rgba(27, 21, 54, 0.1);
        flex: 0 0 50%;
        max-width: 50%;
        &:nth-child(2n) {
          border-left: 0; }
        .module {
          color: $secondary;
          @include font-600; }
        h4 {
          color: $primary;
          margin-bottom: rem(24);
          position: relative;
          &:after {
            content: "";
            width: rem(240);
            height: 1px;
            background: rgba(27, 21, 54, 0.25);
            position: absolute;
            top: 100%;
            left: 0; } } } } }

  .upcoming-course {
    padding: rem(120) 0;
    background: #F6F7F9;
    .section-heading,
    .section-subheading {
      margin-bottom: rem(40); } }

  .course-class {
    .col-md-6,
    .col-lg-6 {
      &:nth-child(2n) {
        .cc-card {
          border-left: 0; } } }
    .cc-card {
      padding: rem(40);
      background: #FFF;
      border: 1px solid rgba(27, 21, 54, 0.1);
      .tag {
        margin-bottom: rem(24); }
      .dt-class {
        margin-bottom: rem(24);
        h4 {
          margin-bottom: 0; }
        .time {
          font-size: rem(18);
          line-height: rem(28); } }
      .h2 {
        color: $primary;
        @include font-700;
        margin-bottom: rem(24); }
      .btn-wrap {
        .btn {
          margin-bottom: rem(8); }
        span {
          color: rgba(27, 21, 54, 0.75); } } } }

  .career-transformation {
    padding: rem(120) 0;
    position: relative;
    &:before {
      content: "";
      width: calc(100% - #{rem(56)});
      height: rem(500);
      background: $primary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
    .container {
      position: relative;
      z-index: 2; }
    .section-heading {
      margin-bottom: rem(24);
      .big-title {
        color: #FFF; } }
    .transform-video {
      .nav-tabs {
        display: flex;
        align-items: center;
        gap: rem(16);
        margin-bottom: rem(64);
        .nav-link {
          padding: rem(20);
          border: 1px solid rgba(255, 255, 255, 0.25);
          color: rgba(255, 255, 255, 0.75);
          display: block;
          text-decoration: none;
          transition: all .3s ease-in-out;
          &:hover,
          &.active {
            border-color: #FFF;
            color: $primary;
            background: #FFF; } } } }
    .tab-content {
      padding-bottom: rem(80); }
    .video-wrap {
      margin-bottom: rem(16); }
    .video-card {
      margin-bottom: rem(24);
      .thumbnail {
        padding-top: rem(240); } } }
  .seemore {
    .btn-border {
      border: 1px solid #B1B8D9;
      color: #5567C3;
      margin: 0 auto; } }

  .transformation-area {
    padding: rem(80) 0 0;
    border-top: 1px solid rgba(27, 21, 54, 0.1);
    .row {
      margin-bottom: rem(16); } }
  .transformation-card {
    padding: rem(24);
    border: 1px solid rgba(27, 21, 54, 0.25);
    background: #FAFAFA;
    margin-bottom: rem(24);
    height: 100%;
    max-height: calc(100% - #{rem(24)});
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(72);
      p {
        margin-bottom: 0; } }
    .content {
      min-height: rem(112);
      margin-bottom: rem(24);
      p {
        color: #1B1536; }
      .remaining {
        display: none; }
      .readmore {
        color: $secondary;
        display: none; } }
    .show-readmore {
      & > p:not(:fisrt-child) {
        display: none; }
      .readmore {
        display: inline; }

      &.expanded {
        & > p {
          display: none; }
        .ellipsis,
        .shortened {
          display: none; }
        .remaining {
          display: block; } } }
    .profile {
      display: flex;
      align-items: center;
      gap: rem(8);
      .avatar {
        width: rem(40);
        height: rem(40);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50px; } } } }

  .program-studentsays {
    padding: rem(120) 0;
    .section-heading {
      text-align: center;
      margin-bottom: rem(64); }
    .video-wrap {
      margin-bottom: rem(64);
      .col-md-4 {
        margin-bottom: rem(24); }
      .seemore {
        margin-top: rem(16); } } }

  .courseleaders-detail {} }

.courselead {
  padding: rem(120) 0;
  background: #FFF;
  .section-heading {
    margin-bottom: rem(40); }
  &-card {
    padding: rem(40);
    border: 1px solid rgba(27, 21, 54, 0.1);
    display: flex;
    flex-wrap: wrap;
    .photo {
      flex: 0 0 rem(240);
      max-width: rem(240);
      padding: 60.3567% 0 0;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    .detail {
      flex: 0 0 calc(100% - #{rem(240)});
      max-width: calc(100% - #{rem(240)});
      padding-left: rem(24);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        color: $primary;
        margin-bottom: rem(4); }
      .title {
        @include font-600;
        color: rgba(27, 21, 54, 0.75);
        margin-bottom: rem(16); }
      .more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        .profile-socmed {
          display: flex;
          align-items: center;
          gap: rem(16);
          a {
            transition: all .3s ease-in-out;
            &:hover {
              transform: translateY(-4px); } } } } } } }

// Consultation
.consultation {
  &-enquiry {
    padding: rem(120) 0;
    background: #1F183E;
    .section-heading,
    .section-subheading {
      max-width: rem(650);
      .big-title,
      p {
        color: #FFF; } }
    h3 {
      color: #FFF; }
    .enquiry-box {
      background: #FFF;
      padding: rem(24);
      .form-action {
        display: flex;
        justify-content: flex-end; } } }
  &-detail {
    .profile-detail {
      padding-bottom: rem(120); } } }

// Events
.events {
  .masthead {
    .masthead-img {
      &:last-child {
        margin-bottom: 0; } } }
  .masthead-evdet {
    padding-bottom: 0; }
  &-list {
    padding: 0 0 rem(96) 0;
    .nav-tabs {
      display: flex;
      align-items: center;
      gap: rem(16);
      margin-bottom: rem(40);
      .nav-link {
        padding: rem(20) rem(32);
        border: 1px solid rgba(27, 21, 54, 0.25);
        background: #FFF;
        display: block;
        color: rgba(27, 21, 54, 0.75);
        @include font-600;
        transition: all .3s ease-in-out;
        &:hover,
        &.active {
          background: $primary;
          border-color: $primary;
          color: #FFF;
          text-decoration: none; } } }

    .events-card {
      position: relative;
      width: 100%;
      min-height: rem(392);
      height: 100%;
      &-wrap {
        .col-md-4 {
          margin-bottom: rem(24); } }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
      .content {
        color: #FFF;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        padding: rem(24);
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        h4 {
          margin-bottom: 0;
          @include font-600;
          a {
            color: #FFF;
            &:hover {
              text-decoration: none; } } }
        p {
          opacity: 0.75; }
        .dateaction {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .btn-link {
            color: #FFF;
            @include font-400;
            &:after {
              background: url(../images/ic-arrow-right-white.svg); } } } } } }
  &-details {
    position: relative;
    padding: rem(64);
    &:before {
      content: "";
      width: 100%;
      height: rem(40);
      max-width: calc(100% - 43.014vw);
      position: absolute;
      top: rem(-38);
      left: 0;
      background: #fff; }
    &:after {
      content: "";
      width: 100%;
      max-width: 43.014vw; //rem(533)
      height: 100%;
      background: linear-gradient(179.99deg, #F6F7F9 0.01%, rgba(246, 247, 249, 0) 96.58%);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0; }
    .feedate {
      margin-bottom: rem(80);
      .row {
        .col-md-4 {
          &:first-child {
            .card {
              border-right: 0; } }
          &:last-child {
            .card {
              border-left: 0; } } } }
      .card {
        padding: rem(16);
        border: 1px solid rgba(27, 21, 54, 0.1);
        height: 100%;
        small {
          @include font-600;
          color: $secondary;
          display: block;
          margin-bottom: 8px;
          font-size: rem(14);
          line-height: rem(20); }
        .val {
          font-size: rem(20);
          line-height: rem(28);
          color: #1B1536;
          @include font-700; }
        p {
          color: rgba(27, 21, 54, 0.75); } } }
    h2 {
      @include font-700;
      color: $primary;
      font-size: rem(48);
      line-height: rem(56);
      margin-bottom: rem(24); }
    .left-content,
    .right-content {
      position: relative;
      z-index: 2; }
    .left-content {
      padding-right: rem(48);
      @include responsive(1599px, 0) {
        padding-right: rem(40); } }
    .about-events,
    .takeaways {
      margin-bottom: rem(80);
      font-size: rem(18);
      line-height: rem(28); }
    .takeaways {
      .custom-list {
        margin-bottom: rem(80); } }

    .speaker {
      .box-speaker {
        padding: rem(40);
        border: 1px solid rgba(27, 21, 54, 0.1);
        .name {
          font-size: rem(24);
          line-height: rem(32);
          margin-bottom: 8px; }
        .position {
          margin-bottom: rem(16);
          @include font-600; } } }

    .right-content {
      padding-left: rem(48);
      width: 100%;
      max-width: rem(445);
      @include responsive(0, 1601px) {
        padding-left: rem(40); } }
    .form-reserve {
      padding: rem(24);
      background: #FFF;
      border: 1px solid rgba(27, 21, 54, 0.1);
      margin-bottom: rem(24);
      width: 100%;
      max-width: rem(445);
      .form-action {
        display: flex;
        justify-content: flex-end; } }
    .tos {
      max-width: 100%;
      a {
        @include font-600;
        color: $primary; } } } }

// Blog
.featured-reads {
  padding: rem(80) 0;
  background: #F6F7F9;
  h2 {
    font-size: rem(32);
    line-height: rem(40);
    @include font-600;
    margin-bottom: rem(24); }
  .col-md-6 {
    &:nth-child(2n) {
      .reads-card {
        border-left: 0; } } }
  .reads-card {
    padding: rem(40);
    border: 1px solid rgba(27, 21, 54, 0.1);
    display: flex;
    align-items: stretch;
    gap: rem(24);
    background: #FFF;
    height: 100%;
    .thumbnail {
      flex: 0 0 rem(240);
      max-width: rem(240);
      position: relative;
      img {
        width: 100%;
        height: rem(200);
        object-fit: cover; }
      .num {
        padding: 0 rem(12);
        background: #FFF;
        border: 1px solid #1B1536;
        border-radius: 50px;
        max-width: max-content;
        position: absolute;
        top: rem(-8);
        left: rem(-20); } }
    .content {
      // flex: 0 0 calc(100% - #{rem(240)})
      max-width: calc(100% - #{rem(240)});
      min-height: rem(200);
      height: 100%;
      display: flex;
      flex-direction: column; }

    .author {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      &-pp {
        width: rem(24);
        height: rem(24);
        border-radius: 50px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%; } }
      &-name {
        padding-left: rem(8);
        @include font-600;
        color: $primary; } }
    .title {
      margin-bottom: rem(24);
      h4 {
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(8);
        a {
          color: $primary;
          &:hover {
            text-decoration: none;
            color: lighten($primary, 20%); } } }
      .date-cat {
        display: flex;
        align-items: center;
        .date {
          font-size: rem(14);
          line-height: rem(20);
          color: #666666; }
        .cat {
          font-size: rem(14);
          line-height: rem(20);
          color: #E05B11;
          padding-left: rem(20);
          position: relative;
          @include font-600;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50px;
            background: #D9D9D9;
            position: absolute;
            top: 8px;
            left: 8px; } } } }
    .more {
      margin-top: auto; } } }

.articles-reads {
  padding: rem(80) 0;
  // background: #F6F7F9
  .reads-wrap {
    .col-md-6 {
      margin-bottom: rem(24); }
    & + .seemore {
      margin-top: rem(16); } }

  .h3 {
    @include font-700; }
  .nav-tabs {
    display: flex;
    align-items: center;
    gap: rem(16);
    margin-bottom: rem(40);
    .nav-link {
      padding: rem(20) rem(32);
      border: 1px solid rgba(27, 21, 54, 0.25);
      background: #FFF;
      display: block;
      color: rgba(27, 21, 54, 0.75);
      @include font-600;
      transition: all .3s ease-in-out;
      &:hover,
      &.active {
        background: $primary;
        border-color: $primary;
        color: #FFF;
        text-decoration: none; } } }
  .readpost-card {
    height: 100%;
    .thumbnail {
      position: relative;
      img {
        width: 100%;
        height: rem(240);
        object-fit: cover; }
      .num {
        padding: 0 rem(12);
        background: #FFF;
        border: 1px solid #1B1536;
        border-radius: 50px;
        max-width: max-content;
        position: absolute;
        top: rem(-8);
        left: rem(-20); } }
    .content {
      border: 1px solid rgba(27, 21, 54, 0.1);
      padding: rem(24);
      border-top: 0;
      height: calc(100% - #{rem(240)});
      display: flex;
      flex-direction: column; }
    .author {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      &-pp {
        width: rem(24);
        height: rem(24);
        border-radius: 50px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%; } }
      &-name {
        padding-left: rem(8);
        @include font-600;
        color: $primary; } }
    .title {
      margin-bottom: rem(24);
      h4 {
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(8);
        a {
          color: $primary;
          &:hover {
            text-decoration: none;
            color: lighten($primary, 20%); } } }
      .date-cat {
        display: flex;
        align-items: center;
        .date {
          font-size: rem(14);
          line-height: rem(20);
          color: #666666; }
        .cat {
          font-size: rem(14);
          line-height: rem(20);
          color: #E05B11;
          padding-left: rem(20);
          position: relative;
          @include font-600;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50px;
            background: #D9D9D9;
            position: absolute;
            top: 8px;
            left: 8px; } } } }
    .more {
      // margin-top: rem(24)
      margin-top: auto; } }
  .seemore {
    .btn-border {
      border: 1px solid #B1B8D9;
      color: #5567C3;
      margin: 0 auto; } } }

.singleblog {
  &-masthead {
    padding-bottom: 0;
    &.masthead-typetwo {
      h1 {
        margin-bottom: 0; } }
    .row {
      align-items: flex-end;
      .col-md-4 {
        text-align: right; } }
    .masthead-img {
      margin-bottom: 0; }
    .btn-back {
      color: #5567C3;
      margin-bottom: rem(40);
      &:after {
        background: url(../images/ic-arrow-right.svg) no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(-180deg); }
      &:hover {
        &:after {
          transform: rotate(-180deg) translateX(8px); } } }

    .datecat {
      .date {
        font-size: rem(18);
        line-height: rem(28); }
      .cat {
        font-size: rem(20);
        line-height: rem(32);
        color: #E05B11;
        @include font-600;
        position: relative;
        margin-left: rem(20);
        &:hover {
 }          // text-decoration: none
        &:before {
          content: "";
          width: 4px;
          height: 4px;
          background: #D9D9D9;
          border-radius: 50px;
          position: absolute;
          top: rem(10);
          left: rem(-15); } } } }
  &-side {
    width: 100%;
    max-width: rem(288);
    transition: all .3s ease-in-out;
    h4 {
      font-size: rem(16);
      line-height: 1.5em;
      color: rgba(27, 21, 54, 0.75);
      margin-bottom: rem(16); }
    .author {
      display: flex;
      align-items: flex-start;
      .pp {
        width: rem(40);
        flex: 0 0 rem(40); }
      .namepos {
        flex: 0 0 calc(100% - #{rem(40)});
        max-width: calc(100% - #{rem(40)});
        padding-left: rem(8);
        margin-bottom: rem(16);
        .name {
          @include font-600;
          color: $primary;
          margin-top: -4px; }
        .pos {
          color: rgba(27, 21, 54, 0.5); } } }
    .authorspecs {
      margin-bottom: rem(40);
      p {
        font-size: rem(14);
        line-height: rem(20); } }
    .blogcat {
      margin-bottom: rem(40);
      .cat-item {
        display: block;
        padding: rem(12) rem(16);
        border: 1px solid rgba(27, 21, 54, 0.25);
        color: rgba(27, 21, 54, 0.75);
        @include font-600;
        transition: all .3s ease-in-out;
        &:hover {
          text-decoration: none; }
        &:not(:last-child) {
          margin-bottom: rem(8); }
        &.active,
        &:hover {
          background: $primary;
          border-color: $primary;
          color: #FFF; } } }

    .tagsbox {
      margin-bottom: rem(40);
      .tag {
        margin-bottom: rem(8);
        display: inline-block;
        &:not(:last-child) {
          margin-right: rem(8); } } }
    .sharebox {}
    .shareicon {
      display: inline-block;
      vertical-align: middle;
      transition: all .3s ease-in-out;
      &:not(:last-child) {
        margin-right: rem(16); }
      img {
        width: rem(26); // rem(20)
        height: rem(26); } // rem(20)
      &:hover {
        transform: translateY(-4px); } } }

  &-content {
    padding: rem(80) 0;
    & > .container > .row {
      justify-content: space-between;
      & > .col-lg-9 {
        @include minltablet() {
          flex: 0 0 66.666667%;
          max-width: 66.666667%; } } } } }


.article-nextprev {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; //flex-end
  margin-bottom: 2rem;
  margin-top: rem(80);
  padding: rem(80) 0 0;
  border-top: 1px solid rgba(27, 21, 54, 0.1);
  .article_prev,
  .article_next {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .prev,
    .next {
      color: #5567C3;
      margin-bottom: 16px;
      display: inline-block;
      margin-bottom: 16px; }
    .btn-arrow {
      color: #5567C3;
      margin-bottom: rem(16); }

    h4 {
      max-width: rem(600);
      a {
        color: $primary;
        font-size: rem(32);
        line-height: rem(40);
        text-decoration: none; }
      &:hover {
        a {
          text-decoration: none; } } } }
  .article_prev {
    text-align: left;
    align-items: flex-start;
    .btn-arrow {
      padding-right: 0;
      padding-left: rem(32);
      &:after {
        right: auto;
        left: 0;
        transform: rotate(-180deg); }
      &:hover {
        &:after {
          transform: rotate(-180deg) translateX(4px); } } } }
  .article_next {
    text-align: right;
    margin-left: auto; } }

// About
.about {

  .masthead-four {
    .masthead-img {
      img {
        height: 90vh;
        object-fit: cover; } } }
  &-people {}

  &-founder {
    padding: rem(120) 0 rem(260);
    background-color: $primary;
    color: #FFF;
    background-size: auto 100%;
    background-position: right top;
    background-repeat: no-repeat;
    .foundername,
    .founder-pos {
      color: #FFF; }
    .foundername {
      font-size: rem(48);
      line-height: rem(56);
      @include font-700;
      margin-bottom: rem(8); }
    .founder-pos {
      margin-bottom: rem(16);
      @include font-600; }
    .text {
      opacity: .75; } }

  &-career {
    padding: rem(120) 0;
    background: #F6F7F9;
    .section-subheading {
      margin-bottom: rem(48);
      p {
        font-size: rem(18);
        list-heigth: 1.5em; } }
    .accordion-group {
      .panel-title {
        a {
          font-size: rem(24);
          line-height: rem(32);
          @include minltablet() {
            .col-md-7 {
              flex: 0 0 75%;
              max-width: 75%; }
            .col-md-5 {
              flex: 0 0 25%;
              max-width: 25%; } } } }
      .panel-body {
        @include minltablet() {
          p, ol, ul {
            max-width: rem(640); } } } } }

  &-quote {
    padding: rem(228) 0;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    blockquote {
      padding: 0;
      padding-top: rem(40);
      border: 0;
      font-size: rem(40);
      line-height: rem(48);
      color: #fff;
      text-align: center;
      q {
        &:before {
          content: "";
          width: rem(50);
          height: rem(24);
          background: url(../images/ic-about-quote.svg) no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto; }
        &:after {
          display: none; } } } }
  &-gallery {
    img {
      width: 100%;
      height: auto; } }
  &-comunitydesc {
    padding: rem(120) 0;
    background: $primary;
    color: #FFF;
    .section-heading {
      .big-title {
        color: #FFF; } }
    .section-subheading {
      font-size: rem(18);
      line-height: rem(28); } }
  &-ourstudentare {
    padding-bottom: rem(120);
    background: $primary;
    color: #FFF;
    .section-heading {
      .big-title {
        color: #FFF; } }
    .studentcharm {
      &-wrap {
        margin-bottom: rem(40);
        .col-lg-4 {
          margin-bottom: rem(24); } }
      &-box {
        padding: rem(24);
        background: #251D49;
        border-left: 2px solid #FFF;
        color: #FFF;
        font-size: rem(18);
        line-height: rem(28); } } }
  &-convertation {
    padding: rem(120) 0 rem(96);
    .section {
      &-heading,
      &-subheading {
        margin-bottom: rem(64); } } }
  &-explore {
    padding: rem(120) 0 rem(40);
    position: relative;
    background: #FFF;
    &:before {
      content: "";
      width: calc(100% - #{rem(56)});
      height: calc(100% + #{rem(84)});
      background: #F6F7F9;
      position: absolute;
      top: 0;
      left: 0; }
    .section-heading,.section-subheading {
      margin-bottom: rem(64); }
    .section-subheading {
      p {
        font-size: rem(18);
        line-height: rem(28); } }
    .card-explore {
      .thumbnail {
        width: 100%;
        padding: 40.9% 0;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0; } }
      .content {
        padding: rem(24);
        background: #FFF;
        h4 {
          margin-bottom: 8px; } } } }
  &-ourjourney {
    padding: rem(200) 0 rem(120);
    background: $primary;
    .section-heading {
      margin-bottom: rem(80);
      .big-title {
        color: #FFF;
        margin-bottom: 0; } }
    .timeline {
      &-wrap {}
      &-item {
        display: flex;
        color: #FFF;
        position: relative;
        &:not(:last-child) {
          margin-bottom: rem(80);
          .content {
            &:after {
              content: "";
              width: 1px;
              height: 180%;
              border-left: 1px dashed #FFF;
              position: absolute;
              left: rem(-28);
              top: rem(24);
              opacity: .5; } } }
        .date {
          min-width: rem(120);
          margin-right: rem(56);
          span {
            display: block;
            padding: 0 rem(24);
            background: #2E2849;
            border-radius: 50px;
            color: #FFF;
            line-height: 1.8em; } }
        .content {
          width: 100%;
          max-width: rem(800);
          margin-right: rem(72);
          color: rgba(255, 255, 255, 0.75);
          position: relative;
          &:before {
            content: "";
            width: rem(16);
            height: rem(16);
            background: url(../images/ellipse-timeline.svg) no-repeat center;
            border-radius: 50px;
            position: absolute;
            top: 8px;
            left: rem(-36); } }
        .year {
          font-size: rem(64);
          line-height: rem(76);
          color: #FFFFFF;
          @include font-700;
          opacity: .05; } } } }
  &-whyselfstro {
    padding: rem(80) 0;
    .section-heading {
      margin-bottom: rem(32);
      .big-title {
        margin-bottom: 0; } }
    .col-md-6 {
      margin-bottom: rem(42); }
    .why-card {
      .icon {
        width: rem(32);
        height: rem(32);
        margin-bottom: rem(8); }
      h3 {
        position: relative;
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(24);
        &:after {
          content: "";
          width: 100%;
          max-width: rem(240);
          height: 1px;
          background: rgba(27, 21, 54, 0.25);
          position: absolute;
          left: 0;
          bottom: rem(-8); } } } }
  &-allgallery {
    padding: rem(120) 0;
    background: #F6F7F9; }
  .courselead {
    .section-heading {
      .big-title {
        font-size: rem(32);
        line-height: 1.3em; } } } }

// Contact
.contact {
  &-top {
    background: #FFF; //#F8F9FB
    padding: rem(196) 0 rem(80);
    position: relative;
    &:before {
      content: "";
      width: 49.167vw;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(0.59deg, #F6F7F9 12.22%, rgba(246, 247, 249, 0) 75.78%); }
    .section-heading {
      .tag {
        margin-bottom: rem(16); } }
    .section-subheading {
      font-size: rem(24);
      line-height: rem(32); }
    .right-content {
      padding-left: rem(48);
      h3 {
        font-size: rem(32);
        line-height: rem(40);
        @include font-600;
        margin-bottom: rem(24); } } }
  &-bottom {
    padding: rem(80) 0;
    background: #F6F7F9;
    .col-md-6 {
      margin-bottom: rem(40); }
    .info-card {
      .icon {
        width: rem(32);
        height: rem(32);
        margin-bottom: 8px;
        img {
          width: 100%;
          height: 100%; } }
      .title {
        position: relative;
        padding-bottom: rem(8);
        &:after {
          content: "";
          width: 100%;
          max-width: rem(240);
          height: 1px;
          background: rgba(27, 21, 54, 0.25);
          position: absolute;
          left: 0;
          bottom: 0; } }
      a {
        @include font-600; } } } }


// Thankyoupage & 404
.thanknot {
  min-height: 100vh;
  position: relative;
  .background {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: left bottom; }
  .box-left,
  .box-right {
    width: 100%;
    min-height: 100vh;
    display: flex;
    // align-items: center
    padding-top: 25vh;
    .content {
      max-width: rem(520);
      margin: 0;
      color: #FFF;
      .tag {
        color: #FFF;
        border-color: #FFF;
        margin-bottom: rem(24); }
      .section-heading {
        margin-bottom: rem(24);
        h2 {
          font-size: rem(48);
          line-height: rem(56);
          color: #FFF;
          margin-bottom: 0; }
        .large {
          line-height: 1em;
          font-size: 9.375vh;
          @include font-700; } }
      .section-subheading {
        font-size: rem(18);
        line-height: rem(28); } } }
  .box-right {
    justify-content: flex-end;
    .content {
      max-width: rem(544);
      .section-subheading {
        color: rgba(27, 21, 54, 0.75);
        font-size: rem(24);
        line-height: rem(32);
        .btn {
          margin-top: rem(40);
          font-size: rem(16);
          line-height: 1em; } } } }
  &-logo {
    position: absolute;
    top: rem(80);
    left: rem(80);
    img {
      height: rem(28); } }

  &-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: rem(40) 0;
    .footer-left {
      color: #FFF;
      max-width: rem(450); } }
  &-socmed {
    padding-left: rem(80);
    span {
      @include font-600;
      margin-bottom: rem(8);
      display: block; }
    .follow-socmed {
      display: flex;
      align-items: center;
      gap: rem(16); } } }


// Responsive

@include responsive(1399px, 1025px) {
  .about {
    &-explore {
      &:before {
        width: calc(100% - 32px); } } } }


@include max-ipadpro() {

  .homepage {
    &-masthead {
      .masthead-content {
        h1 {
          line-height: 1.250em; } } }
    .featured {
      &-parenter {
        .wrapped-items {}
        .partner-logo {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
          &:nth-child(3n) {
            border-right: 0; }
          &:nth-last-child(-n+3) {
            border-bottom: 0; }
          &:last-child {} } } }
    &-ouracademy {
      &:before {
        width: calc(100% - #{rem(22)});
        height: calc(100% + 4.556vw); } }
    &-ourstudent {
      padding-top: rem(126); }
    &-founders {
      background-size: 50%;
      background-position: right bottom; } }

  .study {
    .compehersive {
      .card-index {
        padding: rem(24); } }

    .programmelist {
      .programme-card {
        padding: rem(24);
        .price-box {
          display: block;
          .price {
            font-size: rem(24);
            line-height: 1.5em;
            margin-bottom: rem(16); }
          .btn-arrow {
            padding-left: 0; } } } }

    &.program-detail {
      .masthead-video {
        h1 {
          font-size: rem(64) !important; } } }

    .program-desc {
      hr {
        margin: rem(32) 0; }
      .course-list {
        column-count: 1;
        column-gap: 0; } }

    .course-sylabus {}

    .profile-detail {
      padding-top: 160px;
      .box-detail {
        .profile {
          &-top {
            padding-bottom: rem(70); } } } }

    .career-transformation {
      &:before {
        width: calc(100% - 16px); } } }

  .courselead {
    &-card {
      padding: rem(20);
      height: 100%;
      // display: block
      --siblings: 3;
      .photo {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 120% 0 0;
        margin-bottom: rem(20);
        height: 0; }
      .detail {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        height: 100%;
        flex-grow: 1;
        max-height: calc(100% / var(--siblings));
        article {
          margin-bottom: rem(24); } } } }

  .faq-banner {
    background-position: center bottom;
    .col-md-6:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn {
        width: 100%;
        max-width: 100%;
        & + .btn {
          margin-top: rem(24); } } } }

  .events-list {
    .events-card {
      .content {
        .dateaction {
          display: block;
          .date {} } } } }

  .events-details {
    .feedate {
      .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
        &:last-child {
          .card {
            border-left: 1px solid rgba(27, 21, 54, 0.1) !important; } } } }
    .right-content {
      padding-left: rem(32);
      max-width: rem(373); } }

  .resources {}

  .featured-reads {
    .reads-card {
      padding: rem(30);
      .thumbnail {
        flex: 0 0 rem(206);
        max-width: rem(206); }
      .content {
        felx: 0 0 calc(100% - #{rem(206)});
        max-width: calc(100% - #{rem(206)}); } } }

  .about {
    .featured {
      &-parenter {
        .wrapped-items {}
        .partner-logo {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
          &:nth-child(3n) {
            border-right: 0; }
          &:nth-last-child(-n+3) {
            border-bottom: 0; }
          &:last-child {} } } }
    &-explore {
      &:before {
        width: calc(100% - 16px); } }
    &-ourjourney {
      .timeline-item {
        .date {
          min-width: rem(128); } } }
    .masthead-four {
      .masthead-img {
        img {
          height: 80vh; } } } }

  .contact {
    &-top {
      &:before {
        width: 52.167vw;
        background: linear-gradient(0.59deg, #F6F7F9 12.22%, rgba(246, 247, 249, 0) 75.78%); }
      .section-heading {
        .large {
          font-size: 5rem;
          line-height: 1em; } } } }

  .thanknot {
    .box-left,
    .box-right {
      // padding-top: 15vh
      .content {
        width: 100%;
        .section-heading {
          .large {
            font-size: rem(72);
            line-height: 1.2em; } } } }
    .box-left {
      padding-right: rem(24); }
    .box-right {
      padding-left: rem(24); } } }


@include responsive(1025px, 769px) {
  .courselead {
    &-card {
      min-height: rem(860); } } }

@include tablet() {
  .homepage {
    .featured {
      &-parenter {
        .wrapped-items {}
        .partner-logo {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
          &:nth-child(3n) {
            border-right: 0; }
          &:nth-last-child(-n+3) {
            border-bottom: 0; } } } }
    &-ouracademy {

      .ouracademy-card {
        .thumbnail {
          padding-top: rem(166);
          img {
            object-fit: cover; } } } }
    &-founders {
      background-size: 50%;
      background-position: right bottom;
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: #FFF;
        opacity: .3;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      .container {
        position: relative;
        z-index: 3; } } }
  .courselead {
    &-card {
      min-height: rem(760); } }
  .study {
    .profile-detail {
      padding-top: 120px;
      .box-detail {
        .profile-top {
          padding-bottom: rem(40); } } }
    .transformation {
      &-card {
        .top {
          display: block;
          margin-bottom: rem(32);
          p {
            margin-bottom: rem(20); } } } } }

  .events-list {
    .nav-tabs {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      .nav-link {
        width: max-content; } }
    .events-card-wrap {
      .row > .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%; } } } }

@include mtablet {
  .events-details {
    &:before {
      width: 100%;
      max-width: 100%; }
    &:after {
      display: none; }
    .row {
      .col-lg-7 {
        margin-bottom: rem(64); }
      .col-lg-5 {
        position: relative;
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0 auto;
        // &:before
        //   content: ""
        //   width: 100vw
        //   height: calc(100% + 5rem)
        //   position: absolute
        //   top: 0
        //   left: -24px
 } }        //   background: linear-gradient(2deg, #F6F7F9 0.01%, rgba(246, 247, 249, 0) 96.58%)
    .feedate {
      .row {
        .col-md-4 {
          flex: 0 0 33.333%;
          max-width: 33.333%;
          .card {} } } }
    .right-content {
      padding-left: 0;
      max-width: 100%;
      .form-reserve {
        width: 100%;
        max-width: 100%;
        margin: 0 auto rem(30); } } }
  .featured-reads {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
      &:nth-child(2n) {
        .reads-card {}
        border-left: 1px solid rgba(27, 21, 54, 0.1); }
      &:not(:first-child) {
        .reads-card {
          border-top: 0; } } } }

  .singleblog {
    &-masthead {
      h1 {
        br {
          display: none; } } } }

  .about {
    .masthead-four {
      .content {
        &:before {
          max-width: calc(100% - 16px); } } }
    &-explore {
      &:before {
        height: calc(100% + #{rem(46)}); } } }

  .contact {
    &-top {
      padding-top: rem(180) !important;
      &:before {
        width: 100%;
        height: 40vh;
        background: linear-gradient(180deg, #F6F7F9 36%, rgba(246, 247, 249, 0) 100%);
        bottom: auto;
        top: 0; }
      .col-lg-6 {
        margin-bottom: rem(38); }
      .right-content {
        padding-left: 0; } } } }

@include mobile() {
  .homepage {
    .featured {
      &-parenter {
        .wrapped-items {}
        .partner-logo {
          flex: 0 0 calc(100% / 2);
          max-width: calc(100% / 2);
          padding: rem(16);
          &:nth-child(2n) {
            border-right: 0; }
          &:nth-last-child(-n+2) {
            border-bottom: 0; } } } }
    &-whoweare {
      .whoweare-countdown {
        .col-4 {
          flex: 0 0 50%;
          max-width: 50%; } } }
    &-ouracademy {
      background: #F6F7F9;
      &:before {
        display: none; }
      .row + .ouracademy-wrap {
        margin-top: rem(38); }
      .ouracademy {
        &-wrap {
          .col-md-4 {
            margin-bottom: rem(30); } }
        &-card {
          .thumbnail {
            padding-top: 70.63%; } } } }
    &-founders {
      .section-subheading {
        p:last-child {
          max-width: 70%; } } }

    &-teacher {
      .videocard-wrap {
        [class^=col-]:nth-child(2n+0) {
          transform: translateX(0); }
        .col-md-6 {
          &:nth-child(10),
          &:nth-child(12) {
            display: none; } } } } }

  .courselead {
    &-card {
      height: max-content;
      .photo {
        padding: 80% 0 0; } } }

  .faq-banner {
    background-position: center bottom;
    .col-md-6 {
      margin-bottom: rem(20);
      .btn-primary {
        width: 100%;
        max-width: 100%; } } }

  .study {
    section {
      padding: rem(60) 0 !important; }
    .masthead-typetwo + .courselead {
      padding-top: 0 !important; }
    .course-sylabus {
      .sylabus-wrap {
        .cs-card {
          flex: 100%;
          max-width: 100%; } } }
    .course-class {
      .cc-card {
        .btn-wrap {
          .btn {
            display: block;
            width: 100%;
            max-width: 100%; } } } }
    .profile-detail {
      padding-top: 100px !important;
      background: #F6F7F9;
      .bg-aksen {
        display: none; }
      .box-detail {
        padding-left: 0;
        .profile-top {
          padding-bottom: 0;
          .profile-socmed {
            margin-bottom: rem(36); }
          .leader-photo {
            width: 100%;
            max-width: 100%;
            padding: 64% 0; } } } }
    .career-transformation {
      &:before {
        width: 100%; }
      .transform-video {
        .nav-tabs {
          width: 100%;
          overflow: hidden;
          overflow-x: auto;
          .nav-link {
            width: max-content; } } } }
    .transformation {
      &-card {
        .top {
          display: block;
          margin-bottom: rem(32);
          p {
            margin-bottom: rem(14); } } } } }

  .consultation {
    &-detail {
      .consultation-enquiry {
        .col-md-7 {
          margin-bottom: rem(30); } } } }

  .events {
    &-details {
      .feedate {
        .row {
          .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
            &:not(:first-child) {
              .card {
                border-top: 0; } }
            &:first-child {
              .card {
                border-right: 1px solid rgba(27, 21, 54, 0.1); } } } } }
      .left-content {
        padding-right: 0; }
      .row {
        .col-lg-5 {
          flex: 0 0 100%;
          max-width: 100%; } }
      .speaker {
        .box-speaker {
          padding: rem(26);
          .pp {
            margin-bottom: rem(26);
            img {
              width: 100%; } } } } } }
  .events-list {
    .nav-tabs {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      .nav-link {
        width: max-content; } } }

  .featured-reads {
    .reads-card {
      display: block;
      height: max-content;
      .thumbnail {
        width: 100%;
        max-width: 100%;
        margin-bottom: rem(30); }
      .content {
        width: 100%;
        max-width: 100%; } } }

  .articles-reads {
    .nav-tabs {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      .nav-link {
        width: max-content; } } }

  .singleblog {
    &-masthead {
      padding-top: rem(66);
      h1 {
        margin-bottom: rem(30) !important; }
      .col-md-4 {
        text-align: left !important; } }
    &-content {
      & > .container > .row {
        & > .col-lg-3 {
          order: 2; }
        & > .col-lg-9 {
          order: 1;
          margin-bottom: rem(70); } } }
    &-side {
      width: 100%;
      max-width: 100%; } }

  .article-nextprev {
    display: block;
    .article_prev,
    .article_next {
      width: 100%;
      max-width: 100%; } }

  .about {

    .masthead-four {
      .content {
        .large {
          font-size: rem(56) !important;
          line-height: 1.2em; } } }

    .featured {
      &-parenter {
        .wrapped-items {}
        .partner-logo {
          flex: 0 0 calc(100% / 2);
          max-width: calc(100% / 2);
          &:nth-child(2n) {
            border-right: 0; }
          &:nth-last-child(-n+2) {
            border-bottom: 0; }
          &:last-child {} } } }

    .timeline-wrap {
      display: flex;
      gap: rem(16);
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      .timeline-item {
        display: block;
        margin-bottom: 0;
        padding-top: rem(38);
        display: flex;
        flex-direction: column;


        .content {
          &:before {
            top: rem(-72);
            left: 0; } }
        &:not(:last-child) {
          .content {
            &:after {
              top: rem(-65);
              border-left: 0;
              width: 100%;
              left: rem(16);
              height: 1px;
              border-top: 1px dashed #FFF; } } }
        .date {
          margin-bottom: rem(22); }
        .year {
          margin-top: auto; } } }


    &-explore {
      &:before {
        width: 100%;
        max-width: 100%;
        height: 100%; }
      .container {
        .col-lg-4 {
          margin-bottom: rem(30); } } }

    &-founder {
      background-size: 50%;
      background-position: right bottom;
      position: relative;
      .text {
        p:last-child() {
          max-width: 60%; } } } }

  .thanknot {
    height: auto;
    & > .container > .row {
      & > .col-md-6 {
        &:first-child() {
          order: 2; }
        &:last-child() {
          order: 1; } } }
    .background {
      background-size: 100%;
      height: 76vh;
      min-height: 50vh;
      top: auto;
      bottom: 0; }
    &-logo {
      left: 0; }
    .box-right {
      padding-left: 0;
      min-height: max-content;
      padding-top: rem(156);
      padding-bottom: rem(70); }
    .box-left {
      padding-top: rem(70);
      padding-bottom: rem(70);
      min-height: 50vh; }

    &-footer {
      position: relative;
      .row {
        .col-md-6 {
          &:first-child {
            order: 2; }
          &:last-child {
            order: 1; } } } }
    &-socmed {
      padding-left: 0;
      margin-bottom: rem(38);
      span {
        color: #FFF; } } } }
